import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { BsTabs, SearchService } from "@sinequa/components/search";
import { Tab } from "@sinequa/core/web-services";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { CommonService } from "../../app/services/common.service";
import googleAnalyticsConstants from "./../../app/analytics/google-analytics.constant";

@Component({
  selector: "app-sde-tabs",
  templateUrl: "./sde-tabs.component.html",
  styleUrls: ["./sde-tabs.component.scss"],
})
export class SdeTabsComponent extends BsTabs implements OnInit {
  constructor(
    protected override searchService: SearchService,
    private deviceService: DeviceDetectorService,
    private $gaService: GoogleAnalyticsService,
    private commonService: CommonService,
    public router: Router
  ) {
    super(searchService);
  }

  @Input() searchText: string;
  @Input() sciXCount: number;

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  override ngOnChanges(changes: SimpleChanges) {
    // Check if the inputData property has changed
    if (!!changes["results"]) {
      this.update();
    }

    // Assign sciXCount to the count property of the "Publications" object
    const publications = this.searchtabs?.find(
      (item) => item.name === "Publications"
    );
    if (publications) {
      if (this.commonService.isSDEApp()) {
        publications.count = this.sciXCount ? this.sciXCount : 0;
      } else {
        this.sciXCount = 0;
        publications.count = 0;
      }
    }
  }

  override selectTab(tab: Tab, search = true) {
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.tab,
      tab?.name,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().resultsPage,
        url: this.router.url,
        ...this.commonService.createSearchEventDetailsObject(
          this.searchService.query.text,
          this.commonService.selectedScientificFocus
        ),
      }
    );
    if (tab !== this.currentTab) {
      if (search) {
        this.searchService.selectTab(tab); // the currentTab will be updated in update()
      } else {
        this.currentTab = tab;
      }
      this.events.next(tab);
    }
    return false; // Stop following href
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isTabDisabled(tab: any): boolean {
    // Disable the tab if its name is "Publications" and searchText is empty

    if (this.commonService.isSDEApp()) {
      if (
        tab.name === "Publications" &&
        (!this.searchText || this.searchText.trim() === "")
      ) {
        this.sciXCount = 0;
        return true;
      } else {
        return false;
      }
    } else {
      if (tab.name === "Publications") {
        return true;
      }
      return false;
    }
  }
}

import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule /*, APP_INITIALIZER*/ } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import MatDialog
import { MatDialogModule } from "@angular/material/dialog";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
// @sinequa/components library
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BsHeatmapModule } from "@sinequa/analytics/heatmap";
import { BsTimelineModule } from "@sinequa/analytics/timeline";
import { BsActionModule } from "@sinequa/components/action";
import { BsAdvancedModule } from "@sinequa/components/advanced";
import { BsAlertsModule } from "@sinequa/components/alerts";
import { BsAutocompleteModule } from "@sinequa/components/autocomplete";
import { BsBasketsModule } from "@sinequa/components/baskets";
import { BsFacetModule } from "@sinequa/components/facet";
import { BsFeedbackModule } from "@sinequa/components/feedback";
import { BsLabelsModule } from "@sinequa/components/labels";
import { MLModule } from "@sinequa/components/machine-learning";
import { MetadataModule } from "@sinequa/components/metadata";
import { BsModalModule } from "@sinequa/components/modal";
import { BsNotificationModule } from "@sinequa/components/notification";
import { ResultModule } from "@sinequa/components/result";
import { BsSavedQueriesModule } from "@sinequa/components/saved-queries";
import { BsSearchModule, SearchOptions } from "@sinequa/components/search";
import { BsSelectionModule } from "@sinequa/components/selection";
import { BsUserSettingsModule } from "@sinequa/components/user-settings";
import { SCREEN_SIZE_RULES, UtilsModule } from "@sinequa/components/utils";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
// @sinequa/core library
import { AuditInterceptor } from "@sinequa/core/app-utils";
import { IntlModule, Locale, LocalesConfig } from "@sinequa/core/intl";
import { LoginInterceptor, LoginModule } from "@sinequa/core/login";
import { ModalModule } from "@sinequa/core/modal";
import { NotificationsInterceptor } from "@sinequa/core/notification";
import {
  StartConfig,
  StartConfigWebService,
  WebServicesModule,
} from "@sinequa/core/web-services";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
// ngx-bootstrap library
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsetConfig, TabsModule } from "ngx-bootstrap/tabs";
//Library for multiline text ellipsis
import { EllipsisModule } from "ngx-ellipsis";
import { JoyrideModule } from "ngx-joyride";

//Masonry
import { NgxMasonryModule } from "ngx-masonry";
import { NestedEllipsisModule } from "ngx-nested-ellipsis";
// Environment
import { environment } from "../environments/environment";
import deLocale from "../locales/de";
import enLocale from "../locales/en";
import frLocale from "../locales/fr";
// Components
import { PreviewModule } from "@sinequa/components/preview";
import { AppComponent } from "./app.component";
import { AppResolver } from "./app.resolver";
import { LazyImgDirective } from "./directives/lazy-img.directive";
import { OutsideClickDirective } from "./directives/outside-click.directive";
import { ScrollAdjustDirective } from "./directives/scroll-adjust.directive";

//import { UpdateComponent } from "./home/updates-container/update/update.component";
import { HomeComponent } from "./home/home.component";
import { SearchStoryComponent } from "./home/my-search-story/search-story/search-story.component";
import { NotificationsComponent } from "./home/notifications/notifications.component";
//Services
import { SearchFormComponent } from "@sinequa/components/search-form/search-form.component";
import { CommonComponentsModule } from "./../../../shared-components/src/app/app.module";
import { LandscapeViewPageComponent } from "./../../../shared-components/src/app/landscape-view-page/landscape-view-page.component";
import { MobileViewComponent } from "./../../../shared-components/src/app/mobile-view/mobile-view.component";
import { SdeCommonContactUsPageComponent } from "./../../../shared-components/src/app/sde-common-contact-us-page/sde-common-contact-us-page.component";
import { ImagePreloaderService } from "./../../../shared-components/src/app/services/image-preloader.service";
import { ModalPreviewComponent } from "./library/modal-preview/modal-preview.component";
import { SnackbarComponent } from "./library/snackbar/snackbar.component";
import { MathJaxDirective } from "./mathjax/math-jax.directive";
import { ContentCurationPageComponent } from "./pages/content-curation-page/content-curation-page.component";
import { HelpPageComponent } from "./pages/help-page/help-page.component";
import { ScixMetadataViewerComponent } from "./scix-metadata-viewer/scix-metadata-viewer.component";
import { SearchComponent } from "./search/search.component";
import { CommonService } from "./services/common.service";
import { SdeSearchService } from "./services/sde-search.service";
import { NasaLoaderComponent } from "./shared/nasa-loader/nasa-loader.component";

// Initialization of @sinequa/core
export const startConfig: StartConfig = {
  app: environment.app,
  production: environment.production,
  // autoSAMLProvider: environment.autoSAMLProvider,
  auditEnabled: true,
};

// @sinequa/core config initializer
export function StartConfigInitializer(
  startConfigWebService: StartConfigWebService
): () => Promise<StartConfig | undefined> {
  const init = () => startConfigWebService.fetchPreLoginAppConfig().toPromise();
  return init;
}

// Application routes (see https://angular.io/guide/router)
export const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    resolve: { preloadImg: AppResolver },
  },
  {
    path: "search",
    component: SearchComponent,
    resolve: { preloadImg: AppResolver },
  },
  {
    path: "contact-us",
    component: SdeCommonContactUsPageComponent,
  },
  {
    path: "content-curation-request",
    component: ContentCurationPageComponent,
  },
  // preview is the URL path segment to the PreviewModule
  // the import loads the module from the path
  // when the user navigates to the previewModulePath URL or any childe, the router will fetch the Module and loads the routes
  // The lazy loaded module loads only for the first visit of the URL, it will not load when we revisit that URL again
  // it created a separate chuck for the entire module

  {
    path: "preview",
    loadChildren: () =>
      import(
        "./../../../shared-components/src/app/sde-common-full-preview/sde-common-full-preview.module"
      ).then((m) => m.CommonFullPreviewModule),
  },
  {
    path: "HelpPage",
    component: HelpPageComponent,
    title: "Science Discovery Engine",
  },
  {
    path: "MySearchStory",
    component: SearchStoryComponent,
    resolve: { preloadImg: AppResolver },
  },
  { path: "mobile-view-message", component: MobileViewComponent },
  { path: "landscape-view-message", component: LandscapeViewPageComponent },

  { path: "**", redirectTo: "/home" },
];

// Search options (search service)
export const searchOptions: SearchOptions = {
  routes: ["search"],
  homeRoute: "home",
};

export class AppLocalesConfig implements LocalesConfig {
  defaultLocale: Locale;
  locales?: Locale[];
  constructor() {
    this.locales = [
      { name: "en", display: "msg#locale.en", data: enLocale },
      { name: "fr", display: "msg#locale.fr", data: frLocale },
      { name: "de", display: "msg#locale.de", data: deLocale },
    ];
    this.defaultLocale = this.locales[0];
  }
}

// Screen size breakpoints (consistent with Bootstrap custom breakpoints in app.scss)
export const breakpoints = {
  xl: "(min-width: 1650px)",
  lg: "(min-width: 1400px) and (max-width: 1649.98px)",
  md: "(min-width: 992px) and (max-width: 1399.98px)",
  sm: "(min-width: 576px) and (max-width: 991.98px)",
  xs: "(max-width: 575.98px)",
};

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule,
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    WebServicesModule.forRoot(startConfig),
    IntlModule.forRoot(AppLocalesConfig),
    LoginModule,
    ModalModule,
    MLModule,
    BsSearchModule.forRoot(searchOptions),
    TabsModule,
    BsAutocompleteModule,
    BsNotificationModule,
    BsFacetModule,
    BsActionModule,
    BsModalModule,
    BsBasketsModule,
    BsAlertsModule,
    BsSavedQueriesModule,
    UtilsModule,
    BsLabelsModule,
    BsUserSettingsModule,
    ResultModule,
    BsFeedbackModule,
    MetadataModule,
    BsSelectionModule,
    PopoverModule.forRoot(),
    PreviewModule,
    BsHeatmapModule,
    BsDropdownModule,
    FontAwesomeModule,
    BsTimelineModule,
    BsAdvancedModule,
    MatDialogModule,
    MatSlideToggleModule,
    NgxMasonryModule,
    CollapseModule,
    EllipsisModule,
    NestedEllipsisModule,
    SearchFormComponent,
    BrowserAnimationsModule,
    AccordionModule,
    HttpClientModule,
    PaginationModule.forRoot(),
    JoyrideModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
    NgxGoogleAnalyticsRouterModule,
    CommonComponentsModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    NotificationsComponent,
    SnackbarComponent,
    HelpPageComponent,
    ScixMetadataViewerComponent,
    LazyImgDirective,
    ModalPreviewComponent,
    OutsideClickDirective,
    ScrollAdjustDirective,
    ContentCurationPageComponent,
    SearchStoryComponent,
    MathJaxDirective,
    NasaLoaderComponent,
  ],
  providers: [
    // Provides an APP_INITIALIZER which will fetch application configuration information from the Sinequa
    // server automatically at startup using the application name specified in the URL (app[-debug]/<app-name>).
    // This allows an application to avoid hard-coding parameters in the StartConfig but requires that the application
    // be served from the an app[-debug]/<app name> URL.
    // {provide: APP_INITIALIZER, useFactory: StartConfigInitializer, deps: [StartConfigWebService], multi: true},

    // Provides the Angular LocationStrategy to be used for reading route state from the browser's URL. Currently
    // only the HashLocationStrategy is supported by Sinequa.
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    // Provides an HttpInterceptor to handle user login. The LoginInterceptor handles HTTP 401 responses
    // to Sinequa web service requests and initiates the login process.
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },

    // Provides an HttpInterceptor that offers a centralized location through which all client-side
    // audit records pass. An application can replace AuditInterceptor with a subclass that overrides
    // the updateAuditRecord method to add custom audit information to the records.
    { provide: HTTP_INTERCEPTORS, useClass: AuditInterceptor, multi: true },

    // Provides an HttpInterceptor that automatically processes any notifications specified in the $notifications
    // member of the response body to any Sinequa web service requests.
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationsInterceptor,
      multi: true,
    },

    { provide: SCREEN_SIZE_RULES, useValue: breakpoints },
    TabsetConfig,
    BsDropdownConfig,
    CommonService,
    SdeSearchService,
    ImagePreloaderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { ChangeDetectorRef, Component } from "@angular/core";
import { SponsoredResults } from "@sinequa/components/result/sponsored-results/sponsored-results";
import { SearchService } from "@sinequa/components/search";
import { AppService } from "@sinequa/core/app-utils";
import {
  AuditWebService,
  SponsoredLinksWebService,
} from "@sinequa/core/web-services";

@Component({
  selector: "app-sde-sponsored-links",
  templateUrl: "./sde-sponsored-links.component.html",
  styleUrls: ["./sde-sponsored-links.component.scss"],
})
export class SdeSponsoredLinksComponent extends SponsoredResults {
  constructor(
    protected override changeDetectorRef: ChangeDetectorRef,
    protected override appService: AppService,
    protected override searchService: SearchService,
    protected override sponsoredResultsService: SponsoredLinksWebService,
    protected override auditService: AuditWebService // private commonService: CommonService
  ) {
    super(
      appService,
      searchService,
      sponsoredResultsService,
      auditService,
      changeDetectorRef
    );
  }
}

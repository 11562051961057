<div
  scroll-adjust
  id="leftFilter"
  class="left-filter"
  [ngClass]="{ 'filter-width-mobileView': ui.screenSizeIsLessOrEqual('xs') }"
  *ngIf="ui.screenSizeIsLessOrEqual('xs') && hideFilterTab"
>
  <div
    class="filter-label-mobile font-family-rajdhani-bold"
    *ngIf="ui.screenSizeIsLessOrEqual('xs')"
  >
    <i class="fa-solid fa-filter"></i>
    <span>{{ "Filters" | uppercase }}</span>

    <i
      (click)="toggleFiltersForMobile()"
      class="fa-regular fa-xmark close-filter-icon"
    ></i>
  </div>
  <div
    id="search-results"
    *ngIf="searchService.query.filters && ui.screenSizeIsLessOrEqual('xs')"
  >
    <app-sde-facet-card
      [title]="'Applied Filters'"
      [type]="'applied-filters'"
      [collapsible]="true"
      [startCollapsed]="true"
      ><div class="applied-filter-open">
        <app-sde-applied-filters
          *ngIf="searchService.query.filters"
          [query]="searchService.query"
          [closeOnClickOutside]="true"
          [advancedModeClass]="'position-absolute card border shadow p-3'"
          (filterEdit)="searchService.search()"
        >
        </app-sde-applied-filters>
      </div>
    </app-sde-facet-card>
  </div>

  <div id="search-results">
    <app-sde-search-facets
      *ngIf="hasFacets"
      [results]="results"
    ></app-sde-search-facets>
  </div>
</div>

<ng-container *ngIf="ui.screenSizeIsLessOrEqual('xs')">
  <div
    (click)="!showSciX && !hideFilterTab ? toggleFiltersForMobile() : null"
    [ngClass]="{
      'filter-for-mobile font-family-rajdhani-bold':
        !showSciX && !hideFilterTab,
      'filter-for-mobile-scix font-family-inter-regular':
        showSciX && !showSciXMetadata
    }"
    [joyrideStep]="!showSciX && !hideFilterTab ? 'mobileFilterStep' : null"
    [stepContent]="!showSciX && !hideFilterTab ? mobileFilterContent : null"
  >
    <!-- Conditional Content -->
    <ng-container *ngIf="!showSciX && !hideFilterTab">
      <i class="fa-regular fa-filter mobile-filter-icon"></i>
      <span>FILTERS</span>
    </ng-container>
    <ng-container *ngIf="showSciX && !showSciXMetadata">
      <p>
        Currently, there are no filtering options available for publications
        within SDE.
      </p>
    </ng-container>
  </div>
</ng-container>
<ng-template #mobileFilterContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      {{ getWalkthroughLabelForFilterMobile() }}
    </div>
  </div>

  <p class="joyride-content">
    Click the Filters title to open the filters menu and refine your search
    results .
  </p>
</ng-template>

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { Query } from "@sinequa/core/app-utils";
import { IntlService } from "@sinequa/core/intl";
import { PreviewData, Record } from "@sinequa/core/web-services";
import { Subject, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { RelatedDocumentsWebService } from "../../services/related-documents.web.service";

@Component({
  selector: "app-preview-related-docs-panel",
  templateUrl: "./preview-related-docs-panel.component.html",
  styleUrls: ["./preview-related-docs-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewRelatedDocsPanelComponent implements OnChanges, OnDestroy {
  public similarNotDuplicates: Record[];
  public similarDuplicates: Record[];
  public isOnlyOnePageSimilar = false;
  public isOnlyOnePageDuplicate = false;
  public loading: boolean = false;
  loadCompleteSubscription: Subscription;

  public min_similar: number = 0;
  public disableSimilarNextButton: boolean = false;
  public max_similar: number = 5;
  public disableSimilarPreviousButton: boolean = true;

  public min_duplicates: number = 0;
  public disableDuplicateNextButton: boolean = false;
  public max_duplicates: number = 5;
  public disableDuplicatePreviousButton: boolean = true;

  allRecord: any;

  @Input() previewData: PreviewData;

  _maxNumberInPage = 5;
  _previewData$ = new Subject<PreviewData>();
  _subscription: Subscription;

  @Input() query: Query;
  constructor(
    protected searchService: SearchService,
    protected previewService: PreviewService,
    protected cdr: ChangeDetectorRef,
    protected relatedDocumentsWebService: RelatedDocumentsWebService,
    protected intlService: IntlService
  ) {
    this._subscription = this._previewData$
      .pipe(
        switchMap((previewData) => {
          return this.relatedDocumentsWebService.get(
            previewData.record.id,
            this.query.name,
            this.allRecord.record.exacthash
          );
        })
      )
      .subscribe((similarAll) => {
        this.min_similar = 0;
        this.disableSimilarNextButton = false;
        this.max_similar = this._maxNumberInPage;
        this.disableSimilarPreviousButton = true;

        this.min_duplicates = 0;
        this.disableDuplicateNextButton = false;
        this.max_duplicates = this._maxNumberInPage;
        this.similarNotDuplicates = similarAll.data;
        this.similarDuplicates = similarAll.duplicateDocs;
        this.isOnlyOnePageSimilar = false;
        if (this.similarNotDuplicates.length <= this._maxNumberInPage) {
          this.max_similar = this.similarNotDuplicates.length;
          this.disableSimilarNextButton = true;
          this.isOnlyOnePageSimilar = true;
        }
        this.isOnlyOnePageDuplicate = false;
        if (this.similarDuplicates.length <= this._maxNumberInPage) {
          this.max_duplicates = this.similarDuplicates.length;
          this.disableDuplicateNextButton = true;
          this.isOnlyOnePageDuplicate = true;
        }
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["previewData"]) {
      this.loading = true;
      this.allRecord = JSON.parse(JSON.stringify(this.previewData));
      this._previewData$.next(this.previewData);
    }
  }

  showSimilarTitle() {
    return (
      this.intlService.formatMessage("msg#preview.relateddocuments") +
      " (" +
      this.similarNotDuplicates.length +
      ")"
    );
  }

  showSimilarNext5Records() {
    if (
      this.max_similar + this._maxNumberInPage >=
      this.similarNotDuplicates.length
    ) {
      this.min_similar = this.max_similar;
      this.max_similar = this.similarNotDuplicates.length;
      this.disableSimilarNextButton = true;
    } else {
      this.min_similar += this._maxNumberInPage;
      this.max_similar += this._maxNumberInPage;
      this.disableSimilarNextButton = false;
    }
    this.disableSimilarPreviousButton = false;
  }

  showSimilarPrevious5Records() {
    if (this.min_similar - this._maxNumberInPage < this._maxNumberInPage) {
      this.max_similar = this.min_similar;
      this.min_similar = 0;
      this.disableSimilarPreviousButton = true;
    } else {
      this.max_similar = this.min_similar;
      this.min_similar -= this._maxNumberInPage;

      this.disableSimilarPreviousButton = false;
    }
    this.disableSimilarNextButton = false;
  }

  showDuplicatesNext5Records() {
    if (
      this.max_duplicates + this._maxNumberInPage >=
      this.similarNotDuplicates.length
    ) {
      this.min_duplicates = this.max_duplicates;
      this.max_duplicates = this.similarNotDuplicates.length;
      this.disableDuplicateNextButton = true;
    } else {
      this.min_duplicates += this._maxNumberInPage;
      this.max_duplicates += this._maxNumberInPage;
      this.disableSimilarNextButton = false;
    }
    this.disableDuplicatePreviousButton = false;
  }

  openPreview(record: Record) {
    this.previewService.openRoute(record, this.query);
    this.cdr.detectChanges();
  }

  public documentIconClass(document: Record): string {
    const documentFormat = document.fileext;
    if (!documentFormat) {
      return "far fa-file sq-icon-file";
    }

    return "far fa-file sq-icon-file-" + document.fileext;
  }

  /**
   * Whether the UI is in dark or light mode
   */
  isDark(): boolean {
    return document.body.classList.contains("dark");
  }
}

import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BsActionModule } from "@sinequa/components/action";
import { CollapseModule } from "@sinequa/components/collapse";
import { MetadataModule } from "@sinequa/components/metadata";
import { BsSearchModule } from "@sinequa/components/search";
import { UtilsModule } from "@sinequa/components/utils";
import { IntlModule } from "@sinequa/core/intl";
import { InheritedModule } from "../../inherited/inherited.module";
import { SdeCommonMetadataViewerModule } from "../sde-common-metadata-viewer/sde-common-metadata-viewer.module";
import { SdeEventBasedMetadataViewerModule } from "../sde-event-based-metadata-viewer/sde-event-based-metadata-viewer.module";
import { PreviewRelatedDocsPanelComponent } from "./preview-related-docs-panel/preview-related-docs-panel.component";
import { FullPagePreviewComponent } from "./sde-common-full-preview.component";
import { FullPagePreviewRoutingModule } from "./sde-common-full-preview.routing-module";

@NgModule({
  imports: [
    CommonModule,
    FullPagePreviewRoutingModule,
    IntlModule,
    UtilsModule,
    MetadataModule,
    BsSearchModule,
    SdeCommonMetadataViewerModule,
    SdeEventBasedMetadataViewerModule,
    ScrollingModule,
    BsActionModule,
    CollapseModule,
    InheritedModule,
    MatDialogModule,
  ],
  declarations: [FullPagePreviewComponent, PreviewRelatedDocsPanelComponent],
  providers: [],
  exports: [],
})
export class CommonFullPreviewModule {}

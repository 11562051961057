import { Location } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { PrincipalWebService } from "@sinequa/core/web-services";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { HoverHelpSharedService } from "shared-components/src/app/services/hover-help-shared.service";
import { FEATURES } from "../../config";
import { environment } from "../../environments/environment";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { CommonService } from "../services/common.service";
import { HeaderService } from "../services/header.service";
import { NotificationsComponent } from "./notifications/notifications.component";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  // Whether the left facet bar is shown
  public _showFilters = this.ui.screenSizeIsEqual("md");

  // Whether the menu is shown on small screens
  public _showMenu = false;

  userFullName: string | undefined = "";

  private _shouldShowCuratorMenu: boolean = false;

  notifications: [];
  selectedBackground1: string;
  selectedBackground2: string;
  selectedOption: string;
  dropOptions: any;
  hoverHelpEnabled = false;
  enableNeuralSearch: boolean = false;

  isScientificFocusOpen: boolean = false;
  readMoreMode: string = "initial";
  scientificFocusValues: string[] = [
    "All",
    "Astrophysics",
    "Biological & Physical Sciences",
    "Earth Science",
    "Heliophysics",
    "Planetary Science",
  ];

  selectedOptionImage: string = "assets/img/all-image.svg"; // Replace 'default_image_url' with the URL of your default image
  enableAIAssistedSearch = environment.enableAIAssistedSearch;

  @ViewChild("leftCard") leftCard: ElementRef;
  @ViewChild("rightCard") rightCard: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    setTimeout(() => {
      this.setCardHeights();
    }, 100);
  }

  constructor(
    private router: Router,
    public loginService: LoginService,
    public searchService: SearchService,
    private appService: AppService,
    private commonService: CommonService,
    private hoverHelpSharedService: HoverHelpSharedService,
    private dialog: MatDialog,
    private headerSrv: HeaderService,
    private principalService: PrincipalWebService,
    public ui: UIService,
    private renderer: Renderer2,
    private titleService: Title,
    private $gaService: GoogleAnalyticsService,
    private location: Location
  ) {}

  /**
   * On initialization set the page title
   */
  ngOnInit() {
    this.initializeSelectedOption();
    this.setTitle();
    this.setupUserFullName();
    this.setupNotifications();
    this.setupHoverHelp();
    this.setupNeuralSearch();
  }

  initializeSelectedOption() {
    this.selectedOption = this.scientificFocusValues[0]; // Set the first dropdown option as the default
    this.setScientificFocus(this.selectedOption);
  }

  setTitle() {
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.sdeHomePage
    );
  }

  setupUserFullName() {
    this.principalService.events.subscribe((event) => {
      this.userFullName = this.principalService.principal?.fullName
        ? `${environment.title}-${this.principalService.principal?.fullName}`
        : "Waiting for Authentication...";
    });
  }

  setupNotifications() {
    this.loginService.events.subscribe(() => {
      if (!this.headerSrv.notificationShown && this.appService.app) {
        this.showNotifications();
        this.headerSrv.notificationShown = true;
      }
    });
  }

  setupHoverHelp() {
    this.hoverHelpSharedService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
    });
  }

  setupNeuralSearch() {
    if (this.enableAIAssistedSearch) {
      this.commonService.enableNeuralSearch$.subscribe((enabled) => {
        this.enableNeuralSearch = enabled;
      });

      const neuralSearchStatus = localStorage.getItem("neural-search");
      this.enableNeuralSearch = neuralSearchStatus === "true";
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setCardHeights();
    }, 100);
  }

  setCardHeights() {
    let leftEle = this.leftCard.nativeElement;
    let rightEle = this.rightCard.nativeElement;
    this.renderer.setStyle(rightEle, "height", "fit-content");
    this.renderer.setStyle(leftEle, "height", "fit-content");
    if (leftEle.clientHeight > rightEle.clientHeight) {
      this.renderer.setStyle(rightEle, "height", leftEle.clientHeight + "px");
    } else if (leftEle.clientHeight < rightEle.clientHeight) {
      this.renderer.setStyle(leftEle, "height", rightEle.clientHeight + "px");
    }
  }

  showNotifications() {
    this.notifications = this.appService.app?.data?.notifications as any;
    if (this.notifications != null) {
      this.notifications?.forEach(
        (app: { id: number; title: string; details: string }, index: any) => {
          this.openDialog(app.title, app.details);
        }
      );
    }
  }

  openDialog(title, details) {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      hasBackdrop: true,
      width: "450px",
      height: "200px",
      position: {
        top: "50vh",
        left: "36vw",
      },
      panelClass: "makeItMiddle",
      autoFocus: true,
      disableClose: true,
      backdropClass: "custom-dialog-backdrop-class",
      data: {
        title: title,
        details: details,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  public get showCurator(): boolean {
    this._shouldShowCuratorMenu = this.headerSrv.showCuratorsMenu;
    return this._shouldShowCuratorMenu;
  }

  /**
   * Returns the list of features activated in the top right menus.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get features(): string[] {
    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.features
    ) {
      return <string[]>(<any>this.appService.app.data.features);
    }
    return FEATURES;
  }

  /**
   * Returns the sublist of features which gets displayed on the home page.
   */
  public get homeFacets(): string[] {
    return this.features.filter(
      (feature) =>
        ["saved-queries", "recent-documents", "recent-queries"].indexOf(
          feature
        ) !== -1
    );
  }

  // function which sets scientific focus
  setScientificFocus(focus: string) {
    this.commonService.selectedScientificFocus = focus;
    this.appService.scope = focus;
    this.searchService.query.scope = focus;
  }

  // logic for the changing of the background when we change the dropdown value
  changeBackgroundOfScientificFocus(item) {
    this.selectedOption = item;
    this.setScientificFocus(item);
    this.selectedOptionImage = this.getIconSrc(item);

    switch (item) {
      case "Earth Science":
        this.selectedBackground1 =
          "assets/img/Background/earthScience-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/earthScience-image2.webp";
        break;
      case "Planetary Science":
        this.selectedBackground1 =
          "assets/img/Background/planetary-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/planetary-image2.webp";
        break;
      case "Astrophysics":
        this.selectedBackground1 =
          "assets/img/Background/astrophysics-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/astrophysics-image2.webp";
        break;
      case "Biological & Physical Sciences":
        this.selectedBackground1 =
          "assets/img/Background/biophysical-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/biophysical-image2.webp";
        break;
      case "Heliophysics":
        this.selectedBackground1 =
          "assets/img/Background/heliophysics-image1.webp";
        this.selectedBackground2 =
          "assets/img/Background/heliophysics-image2.webp";
        break;
      case "All":
        this.selectedBackground1 = "assets/img/Background/all-image1.webp";
        this.selectedBackground2 = "assets/img/Background/all-image2.webp";
        break;
      // Add more cases as needed
      default:
        // Handle invalid input
        break;
    }
  }

  getIconSrc(item: string): string {
    // You can implement a logic here to map each item to its corresponding icon URL
    // For example, using a switch statement or an object lookup
    switch (item) {
      case "Earth Science":
        return "assets/img/earth-science.svg";
      case "Planetary Science":
        return "assets/img/planetary-image.svg";
      case "Astrophysics":
        return "assets/img/astrophysics-image.svg";
      case "Biological & Physical Sciences":
        return "assets/img/biophysical-image.svg";
      case "Heliophysics":
        return "assets/img/heliophysics-image.svg";
      case "All":
        return "assets/img/all-image.svg";
      // Add more cases as needed
      default:
        return "";
    }
  }

  // logic to rotate dropdown icon when opened
  toggleScientificFocus() {
    this.isScientificFocusOpen = !this.isScientificFocusOpen;
  }

  toggleReadMoreMode(state: string) {
    this.readMoreMode = state;
    if (state === "disabled") {
      setTimeout(() => {
        this.setCardHeights();
      }, 1000);
    }
  }

  detectReadMoreEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.toggleReadMoreMode("enabled");
    }
  }

  navigateToSearchStory() {
    this.commonService.globalNasaLoader.next(true);

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.mySearchStory,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: this.router.url,
      }
    );
  }

  getSearchStoryURL(): string {
    const origin = this.location.prepareExternalUrl("");
    return `${origin}/app/${environment.app}/#/MySearchStory`;
  }

  navigateToSciXLink() {
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.sciX,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: this.router.url,
      }
    );
  }

  getAriaLabel(item: string): string {
    switch (item) {
      case "Earth Science":
        return "Look for search results across Earth Science focus area";
      case "Planetary Science":
        return "Look for search results across Planetary Science focus area";
      case "Astrophysics":
        return "Look for search results across Astrophysics focus area";
      case "Biological & Physical Sciences":
        return "Look for search results across Biological & Physical Sciences focus area";
      case "Heliophysics":
        return "Look for search results across Heliophysics focus area";
      case "All":
        return "Look for search results across all scientific focus areas";
      default:
        return "";
    }
  }

  // Walkthrough end for HomePage
  endWalkthroughTourForHomePage() {
    this.router.navigate(["/home"]);
  }

  // Hover help end on Homepage
  stopHoverHelpFeature() {
    this.hoverHelpSharedService.toggleHoverHelp(false);
  }

  // Navigate to EJ app - SDE/EJ
  navigateToEjApp() {
    this.commonService.navigateToEjApplicationNewTab();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.environmentalJustice,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: this.router.url,
      }
    );
  }
  // Navigate to TDAMM app - SDE/TDAMM
  navigateToTdammApp() {
    this.commonService.navigateToTdammApplicationNewTab();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.card,
      googleAnalyticsConstants.label.tdamm,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.commonService.getAppDetailsForGA().homePage,
        url: this.router.url,
      }
    );
  }

  toggleNeuralSearch(event) {
    this.commonService.toggleNeuralSearch(event.checked);
    if (event.checked) {
      this.$gaService.event(
        googleAnalyticsConstants.action.toggleOn,
        googleAnalyticsConstants.category.toggle,
        googleAnalyticsConstants.label.aiAssistedSearch,
        0,
        true,
        {
          app_name: this.commonService.getAppDetailsForGA().app_name,
          page: this.commonService.getAppDetailsForGA().homePage,
          debug_mode: environment.googleAnalyticsDebugMode,
          url: this.router.url,
        }
      );
    } else {
      this.$gaService.event(
        googleAnalyticsConstants.action.toggleOff,
        googleAnalyticsConstants.category.toggle,
        googleAnalyticsConstants.label.aiAssistedSearch,
        0,
        true,
        {
          app_name: this.commonService.getAppDetailsForGA().app_name,
          page: this.commonService.getAppDetailsForGA().homePage,
          debug_mode: environment.googleAnalyticsDebugMode,
          url: this.router.url,
        }
      );
    }
  }
}

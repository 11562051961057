<div
  class="filters-toggle-container px-3"
  [ngClass]="{ 'filters-publications': showSciX && !isMobile }"
>
  <div
    *ngIf="showSciX && !isMobile"
    class="filter-panel-scix font-family-inter-regular"
  >
    <p>
      Currently, there are no filtering options available for publications
      within SDE.
    </p>
  </div>

  <!-- MOBILE SCIENTIFIC FOCUS DROPDOWN -->
  <div
    appTooltip="Select the scientific focus area of your search. Only results included in that focus area will appear below.
"
    joyrideStep="ninethStep"
    [stepContent]="customContentNineth"
    *ngIf="isMobile && !commonService.isInTdammApp()"
    class="sci-focus-group btn-group sde-text font-family-rajdhani-regular"
    dropdown
    #dropdown="bs-dropdown"
    [autoClose]="false"
    (isOpenChange)="dropdownToggled($event)"
  >
    <button
      id="button-basic"
      dropdownToggle
      (outsideClick)="handleOutsideClick()"
      type="button"
      class="sci-focus-btn-closed btn btn-primary"
      [ngClass]="
        isSciFocusDropdownActive
          ? 'sci-focus-btn-opened opened-width'
          : 'sci-focus-border-for-mobile'
      "
      aria-controls="dropdown-basic"
    >
      <img
        src="{{ getIconForScientificFocus(currentSciFocus) }}"
        [ngClass]="
          !isSciFocusDropdownActive ? 'isClosed-spacing' : 'isOpened-spacing'
        "
      />
      <div
        *ngIf="isSciFocusDropdownActive"
        class="selected-sci-focus"
        [ngClass]="{
          'infinite-animate-selected':
            currentSciFocus === 'Biological & Physical Sciences' ||
            currentSciFocus === 'Planetary Science'
        }"
      >
        <p>{{ currentSciFocus }}</p>
      </div>
      <i
        *ngIf="isSciFocusDropdownActive"
        [ngClass]="{ 'opened-icon-align': isSciFocusDropdownActive }"
        class="fas down-arrow-icon fa-chevron-down"
      ></i>
    </button>
    <ul
      id="dropdown-basic"
      *dropdownMenu
      class="sci-focus-dropdown dropdown-menu"
      [ngClass]="{ 'opened-width': isSciFocusDropdownActive }"
      role="menu"
      aria-labelledby="button-basic"
    >
      <li role="menuitem" *ngFor="let sciFocus of getSciFocusList()">
        <div
          *ngIf="sciFocus.name !== currentSciFocus"
          (click)="selectScientificFocus(sciFocus.name, dropdown)"
          class="sci-focus-wrapper"
          [sqTooltip]="sciFocus.name"
        >
          <div class="sci-img-wrapper">
            <img [src]="sciFocus.icon" alt="{{ sciFocus.name }}" />
          </div>
          <div
            class="sci-name-wrapper overflow-hidden infinite-animate"
            [ngClass]="{
              'infinite-animate':
                sciFocus.name === 'Biological & Physical Sciences' ||
                sciFocus.name === 'Planetary Science'
            }"
          >
            <a class="sci-focus-item dropdown-item">
              {{ sciFocus.name }}
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <ng-template #customContentNineth>
    <div class="joyride-step-header">
      <div
        class="joyride-step-title"
        [ngClass]="{
          'w-75': ui.screenSizeIsLessOrEqual('sm'),
          'w-100': ui.screenSizeIsGreater('sm')
        }"
      >
        3 - SEARCH FOCUS
      </div>
    </div>
    <p class="joyride-content">
      This dropdown menu includes options to filter results by Scientific Focus
      Area (Earth Science, Planetary Science, Astrophysics, Biological and
      Physical Sciences, Heliophysics, or All).
    </p>
  </ng-template>

  <!-- Search form -->

  <!-- MOBILE SEARCH FORM  -->
  <app-search-form
    appTooltip="The search bar displays the search query text. Click on the search bar to edit the query or begin a new search.
"
    joyrideStep="seventhStep"
    [stepContent]="customContentSeventh"
    [prevTemplate]="prevButton"
    class="flex-grow-1 flex-basis-0 sde-text font-family-rajdhani-regular"
    [ngClass]="{
      'mx-3': !commonService.isInTdammApp()
    }"
    *ngIf="isMobile"
  ></app-search-form>

  <ng-container *ngIf="!showSciX && !isMobile">
    <div
      class="left-group hide-on-mobile"
      id="filter-panel"
      [ngClass]="{
        'left-group': searchService.query.filters,
        'left-group-only-ai-assisted': !searchService.query.filters
      }"
    >
      <button
        appTooltip="Open the filter panel to refine search results by platforms, missions, instruments, and more."
        [hoverHelpEnabled]="hoverHelpEnabled"
        class="btn btn-light filters-toggle font-family-rajdhani-bold sde-text"
        [attr.aria-expanded]="headerSrv.showFilters ? 'true' : 'false'"
        aria-label="Use the filter panel to refine search results by platform, missions, instruments and more."
        joyrideStep="eleventhStep"
        (click)="toggleFiltersEvent()"
        [stepContent]="customContentEleventh"
      >
        <i
          *ngIf="this.ui.screenSizeIsLessOrEqual('sm')"
          [ngClass]="
            isDark() ? 'fa fa-chevron-left px-2' : 'fa fa-chevron-left px-2'
          "
          title="{{ 'msg#search.showFilters' | sqMessage }}"
        ></i>
        <i
          *ngIf="showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
          [ngClass]="
            isDark() ? 'fa fa-chevron-left px-2' : 'fa fa-chevron-left px-2'
          "
          title="{{ 'msg#search.hideFilters' | sqMessage }}"
        ></i>
        <i
          *ngIf="!showFilters && !this.ui.screenSizeIsLessOrEqual('sm')"
          [ngClass]="
            isDark() ? 'fa fa-chevron-right px-2' : 'fa fa-chevron-right px-2'
          "
          title="{{ 'msg#search.showFilters' | sqMessage }}"
        ></i>
        {{ "Filters" | uppercase }}
      </button>
      <ng-template #customContentEleventh>
        <div class="joyride-step-header">
          <div
            class="joyride-step-title"
            [ngClass]="{
              'w-75': ui.screenSizeIsLessOrEqual('sm'),
              'w-100': ui.screenSizeIsGreater('sm')
            }"
          >
            {{ getWalkthroughLabelForFilter() }}
          </div>
        </div>

        <p class="joyride-content">
          Click the Filters title to open the filters menu and refine your
          search results .
        </p>
      </ng-template>

      <ng-container *ngIf="searchService.query.filters">
        <div class="border-left"></div>
        <button
          type="button"
          class="sde-filters-list sde-filter-button sde-filter-button-border font-family-inter-semi-bold"
          (click)="openAppliedFilters()"
        >
          View applied filters
        </button>
      </ng-container>

      <span
        *ngIf="filterCount !== 0 || searchService.query.filters"
        class="sde-filters-list-outer"
        role="button"
        [sqTooltip]="'msg#filters.clearTitle' | sqMessage"
        (click)="clearFilters()"
      >
        <span class="font-family-inter-bold">CLEAR ALL FILTERS</span>
      </span>
      <ng-container *ngIf="enableAIAssistedSearch">
        <!-- Neural search / AI Assisted Search Button -->
        <button
          *ngIf="commonService.isSDEApp()"
          type="button"
          class="font-family-rajdhani-semibold"
          [stepContent]="customContentAIAssistedTwo"
          joyrideStep="step2"
          [ngClass]="{
            'ai-assisted-search-button-enabled-results': enableNeuralSearch,
            'ai-assisted-search-button-disabled-results': !enableNeuralSearch,
            show: hoverHelpEnabled
          }"
          [disabled]="!loginService.complete"
        >
          AI ASSISTED SEARCH
          <mat-slide-toggle
            class=""
            [disableRipple]="true"
            [checked]="enableNeuralSearch"
            (change)="onToggleNeuralSearch($event)"
            [hoverHelpEnabled]="hoverHelpEnabled && !enableNeuralSearch"
            appTooltip="Click on the right side of the AI assisted search toggle bar to turn on this feature."
          >
          </mat-slide-toggle>
        </button>
        <ng-template #customContentAIAssistedTwo>
          <div class="joyride-step-header">
            <div
              class="joyride-step-title"
              style="color: rgb(2, 61, 139)"
              [ngClass]="{
                'w-75': ui.screenSizeIsLessOrEqual('sm'),
                'w-100': ui.screenSizeIsGreater('sm')
              }"
            >
              3 - AI ASSISTED SEARCH
            </div>
          </div>
          <p class="joyride-content">
            Turn on the AI assisted search feature with the toggle bar. Search
            results will now include summaries of the most relevant results.
          </p>
        </ng-template>
        <!-- Neural search Button -->
      </ng-container>
    </div>
  </ng-container>
  <ng-template #customContentSeventh>
    <div class="joyride-step-header">
      <div
        class="joyride-step-title"
        [ngClass]="{
          'w-75': ui.screenSizeIsLessOrEqual('sm'),
          'w-100': ui.screenSizeIsGreater('sm')
        }"
      >
        1 - SEARCH BAR
      </div>
    </div>

    <p class="joyride-content">
      The search bar displays the search query text. Click on the search bar to
      edit the query or begin a new search.
    </p>
  </ng-template>
  <ng-template #prevButton>
    <img
      class="cursor-pointer"
      id="close-icon"
      width="45"
      height="45"
      src="assets/img/walkthrough-previous.svg"
    />
  </ng-template>
</div>

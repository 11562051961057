<div [ngClass]="{ 'hide-mobile-filter-scroll': hideFilterTab }">
  <app-sde-common-header
    [currentQuery]="searchService.query.name"
    [showCuratorMenu]="showCurator"
    [showSciX]="showSciX"
  ></app-sde-common-header>
  <!-- SHIMMER EFFECT -->
  <ng-container *ngIf="this.commonService._loadingFlag">
    <div class="d-flex">
      <div class="shimmer-container-filters">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-filters shimmer-effect"
        ></div>
        <br />
      </div>
      <div class="shimmer-container-results">
        <div
          *ngFor="
            let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
          "
          class="shimmer-box-results-section shimmer-effect"
        ></div>
        <br />
      </div>
    </div>
  </ng-container>

  <!-- Hide the filters, results when no results -->
  <ng-container *ngIf="results">
    <ng-container
      *ngIf="
        !this.commonService._loadingFlag || searchService.totalRowCount == 0
      "
    >
      <!-- 9th STEP WALKTHROUGH TEMPLATE -->
      <ng-template #customContentNineth>
        <div class="joyride-step-header">
          <div
            class="joyride-step-title"
            [ngClass]="{
              'w-75': ui.screenSizeIsLessOrEqual('sm'),
              'w-100': ui.screenSizeIsGreater('sm')
            }"
          >
            3 - SEARCH FOCUS
          </div>
        </div>
        <p class="joyride-content">
          This dropdown menu includes options to filter results by Scientific
          Focus Area (Earth Science, Planetary Science, Astrophysics, Biological
          and Physical Sciences, Heliophysics, or All).
        </p>
      </ng-template>

      <ng-template #customContentSeventh>
        <div class="joyride-step-header">
          <div
            class="joyride-step-title"
            [ngClass]="{
              'w-75': ui.screenSizeIsLessOrEqual('sm'),
              'w-100': ui.screenSizeIsGreater('sm')
            }"
          >
            1 - SEARCH BAR
          </div>
        </div>

        <p class="joyride-content">
          The search bar displays the search query text. Click on the search bar
          to edit the query or begin a new search.
        </p>
      </ng-template>
      <ng-template #prevButton>
        <img
          class="cursor-pointer"
          id="close-icon"
          width="45"
          height="45"
          src="assets/img/walkthrough-previous.svg"
        />
      </ng-template>

      <app-filters-toolbar
        [showSciX]="showSciX"
        [isMobile]="isMobile"
        [showFilters]="headerSrv._showFilters"
        [filterCount]="filterCount"
        [closeDocument]="closeDocument"
        [isDark]="isDark"
        [clearFilters]="clearFilters"
        [handleOutsideClick]="handleOutsideClick"
        [isSciFocusDropdownActive]="isSciFocusDropdownActive"
        [getSciFocusList]="getSciFocusList"
        [currentSciFocus]="currentSciFocus"
        [dropdownToggled]="dropdownToggled"
        [enableNeuralSearch]="enableNeuralSearch"
        (neuralSearchToggled)="toggleNeuralSearch($event)"
        (toggleFilters)="toggleFilters()"
      ></app-filters-toolbar>
    </ng-container>

    <!-- Tabs -->
    <div class="container-fluid main">
      <div
        joyrideStep="firstStepForTdammResults"
        [nextTemplate]="nextButton"
      ></div>
      <div class="d-flex">
        <div
          #filtersBlock
          scroll-adjust
          id="leftFilter"
          class="left-filter"
          [ngClass]="{ 'filter-width-mobileView': isMobile }"
          *ngIf="this.headerSrv.showFilters && !isMobile"
        >
          <div id="search-results">
            <app-sde-search-facets
              *ngIf="hasFacets"
              [results]="results"
            ></app-sde-search-facets>
          </div>
        </div>
        <app-mobile-filter-panel
          [results]="results"
          [showSciX]="showSciX"
          [showSciXMetadata]="showSciXMetadata"
        ></app-mobile-filter-panel>

        <div
          #resultsEl
          [ngClass]="{
            'result-cards-container-noscroll':
              (previewOpened && !isMobile) || (showSciXMetadata && !isMobile),
            'result-cards-container': ui.screenSizeIsGreater('sm'),
            'result-cards-container-mobile': ui.screenSizeIsLessOrEqual('xs')
          }"
        >
          <div
            class="tabs-container navbar-sde-tabs"
            #tabsEl
            joyrideStep="twelveStep"
            [stepContent]="customContentTwelveth"
          >
            <app-sde-tabs
              [searchText]="searchService.query.text"
              [results]="results"
              [sciXCount]="sciXCount"
              (events)="handleTabChange($event)"
            ></app-sde-tabs>
          </div>

          <ng-template #customContentTwelveth>
            <div class="joyride-step-header">
              <div
                class="joyride-step-title"
                style="color: rgb(2, 61, 139)"
                [ngClass]="{
                  'w-75': ui.screenSizeIsLessOrEqual('sm'),
                  'w-100': ui.screenSizeIsGreater('sm')
                }"
              >
                {{ getWalkthroughLabel() }}
              </div>
            </div>
            <p class="joyride-content">
              Switch among these tabs to view particular types of search
              results.
            </p>
          </ng-template>

          <ng-container *ngIf="commonService.resultsCardLoader">
            <div
              *ngFor="
                let item of [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
                ]
              "
              class="shimmer-box-cards shimmer-effect"
            ></div>
            <br />
          </ng-container>

          <app-sde-common-results
            *ngIf="!showSciX"
            (shortPreviewOpened)="toggleFiltersPanel($event)"
            (manualTabNavigation)="tabSwitchedManually($event)"
            [isDidYouMean]="isDidYouMean"
            [didYouMeanObj]="didYouMeanObj"
            [isShortPreviewOpen]="!headerSrv._showFilters"
            [currentAppLabel]="commonService.appLabel"
            [currentQuery]="searchService.query.name"
            [results]="results"
            [showSciX]="showSciX"
            [sciXCount]="sciXCount"
            [neuralData]="neuralData"
          ></app-sde-common-results>

          <div class="container-fluid main">
            <div class="d-flex">
              <div class="d-flex" [ngClass]="{ 'width-35': showSciXMetadata }">
                <div
                  id="results-page"
                  class="result-cards-container"
                  [ngClass]="{
                    'result-cards-container':
                      !this.openedDoc && !this.showSciXMetadata,
                    'result-cards-container-noscroll':
                      this.openedDoc || this.showSciXMetadata,
                    'scix-mobile-view': this.showSciXMetadata && this.isMobile
                  }"
                >
                  <ng-container
                    *ngIf="
                      showSciX &&
                      sciXData &&
                      sciXCount !== 0 &&
                      !commonService.resultsCardLoader &&
                      searchText.trim() !== ''
                    "
                  >
                    <div class="scix-info-container my-3 py-3 px-4">
                      <div
                        class="font-family-rajdhani-bold scix-powered-text pb-3"
                      >
                        Publications on Science Discovery Engine are powered by
                        the NASA Science Explorer (SciX)
                      </div>

                      <div class="scix-link-container">
                        <img
                          src="assets/img/scix-link.svg"
                          alt="Image Alt Text"
                          width="20"
                          height="20"
                        />
                        <a
                          class="font-family-rajdhani-medium scix-link"
                          href="https://scixplorer.org/"
                          target="_blank"
                          >Explore SciX</a
                        >
                      </div>
                    </div>

                    <div
                      class="record d-flex"
                      *ngFor="let doc of sciXData; let i = index"
                      (click)="openScixMetadataViewer(doc, i)"
                      [class.selected]="selectedScixIndex === i"
                      [stepPosition]="'top'"
                      [joyrideStep]="i == 0 ? 'thirteenStep' : 'null'"
                      [stepContent]="customContentThirteen"
                      [hoverHelpEnabled]="hoverHelpEnabled"
                      [appTooltip]="
                        i === 0
                          ? 'Search results are displayed as cards with basic metadata. Click on a card title to open a search result in preview mode and view more information.'
                          : ''
                      "
                    >
                      <div class="flex-grow-1 w-75 ml-0 cursor-pointer">
                        <!--  GitHub Issue #875 - Max-Width Removed  -->
                        <div
                          class="d-flex align-items-center result-title-main-div"
                        >
                          <p
                            class="scix-title font-family-rajdhani-bold"
                            [appMathJax]="doc.title"
                          ></p>
                        </div>

                        <div
                          class="sq-relevant-extracts sq-text ng-star-inserted font-family-public-sans-regular"
                        >
                          <p
                            class="scix-desc"
                            [appMathJax]="doc.abstract"
                            *ngIf="doc.abstract"
                          ></p>
                          <p class="scix-desc" *ngIf="!doc.abstract">
                            No Abstract
                          </p>
                        </div>
                        <span
                          class="extracts-date-scix font-family-inter-regular"
                          >Published On {{ doc.date | sqDate : dateFormat }}
                        </span>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !this.commonService._loadingFlag &&
                      !commonService.resultsCardLoader &&
                      sciXCount == 0 &&
                      tabName == 'Publications'
                    "
                  >
                    <ng-container *ngIf="searchText">
                      <no-data-found></no-data-found>
                    </ng-container>
                    <ng-container *ngIf="!searchText">
                      <no-data-found
                        [customText]="
                          'Searching without a term is not valid for publications. <br /> Please try again with a search term'
                        "
                      ></no-data-found>
                    </ng-container>
                  </ng-container>
                  <div
                    class="row d-flex"
                    *ngIf="
                      showSciX && sciXCount !== 0 && searchText.trim() !== ''
                    "
                  >
                    <div class="col-xs-12 col-12">
                      <pagination
                        previousText="&lsaquo;"
                        nextText="&rsaquo;"
                        firstText="&laquo;"
                        lastText="&raquo;"
                        [boundaryLinks]="showBoundaryLinks"
                        [itemsPerPage]="itemsPerPage"
                        [totalItems]="sciXCount"
                        [maxSize]="5"
                        (pageChanged)="pageChangedForScix($event, searchText)"
                      ></pagination>
                    </div>
                  </div>
                </div>
              </div>
              <div class="width-65" *ngIf="showSciXMetadata">
                <app-scix-metadata-viewer
                  class="scix-container"
                  [scixMetaData]="selectedSciXMetadata"
                  (close)="closeScixMetadataViewer()"
                ></app-scix-metadata-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hover-help-btn" *ngIf="hoverHelpEnabled">
      <button
        class="font-family-rajdhani-medium"
        (click)="stopHoverHelpFeature()"
      >
        <img
          id="close-icon"
          class="close-popup"
          src="assets/img/cross-hover-help.svg"
        />
        CLICK HERE TO EXIT HOVER HELP
      </button>
    </div>

    <ng-template #customContentThirteen>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{ getWalkthroughLabelForResultCard() }}
        </div>
      </div>

      <p class="joyride-content">
        Search results are displayed as cards with basic metadata. Click on a
        card title to open a search result in preview mode.
      </p>
    </ng-template>

    <ng-template #nextButton>
      <img
        class="cursor-pointer"
        id="close-icon"
        width="45"
        height="45"
        src="assets/img/walkthrough-next.svg"
      />
    </ng-template>
  </ng-container>
</div>

<div *ngIf="query.filters" (sqClickOutside)="onClickOutside()">
  <div
    class="sq-simple-mode d-flex flex-row align-items-center"
    *ngIf="!advancedMode"
  >
    <app-sde-applied-filters-list
      [query]="query"
      [filter]="query.filters"
      [showField]="showField"
      [allowRemove]="allowRemove"
      [allowNesting]="allowNesting"
      (filterEdit)="onFiltersChange()"
    ></app-sde-applied-filters-list>
  </div>

  <ng-container *ngIf="ui.screenSizeIsLessOrEqual('xs'); else closeTemplate">
    <div class="p-2 d-flex flex-row align-items-center justify-content-center">
      <button
        class="sde-filters-list font-family-inter-bold padding-for-close"
        (click)="clearFilters()"
      >
        CLEAR ALL FILTERS
      </button>
    </div>
  </ng-container>

  <ng-template #closeTemplate>
    <div
      *ngIf="ui.screenSizeIsGreater('sm')"
      class="p-2 d-flex flex-row align-items-center justify-content-center"
    >
      <button
        class="sde-filters-list font-family-inter-bold padding-for-close"
        (click)="closeFilterPopup()"
      >
        CLOSE
      </button>
    </div>
  </ng-template>
</div>

<div *ngIf="previewData" class="container-fluid">
  <!-- Title and download button -->
  <div class="top-section row px-4" #topSection>
    <div
      [ngClass]="{
        'col-xl-12 col-lg-12 col-md-12': previewType === 'expanded',
        'col-xl-8 col-lg-8 col-md-8 mr-2': previewType !== 'expanded'
      }"
      class="col-sm-12 mt-3 mb-3 record-name d-flex flex-column justify-content-center"
    >
      <div
        *ngIf="previewType !== 'expanded'"
        #ell
        ellipsis="..."
        [ellipsis-content]="previewData.record.title"
        class="preview-not-expanded"
        [sqTooltip]="previewData.record.title"
      ></div>
      <div
        *ngIf="previewType === 'expanded'"
        #ell
        ellipsis="..."
        [ellipsis-content]="previewData.record.title"
        class="preview-expanded"
        [sqTooltip]="previewData.record.title"
      ></div>
    </div>
    <div
      *ngIf="previewType !== 'expanded'"
      class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3 mb-3 record-download-box d-flex justify-content-end align-self-start"
    >
      <ng-container *ngFor="let metadata of metadataViewerOptions">
        <img
          class="mx-2 preview-icon"
          tabindex="0"
          [sqTooltip]="metadata.tooltip | sqMessage"
          [src]="metadata.imageSrc"
          (click)="metadata.function()"
          (keyup.enter)="metadata.function()"
          [alt]="metadata.alt"
          *ngIf="!metadata.hide"
        />
      </ng-container>
    </div>
  </div>
  <div class="metadata-container">
    <div
      #metadataContent
      class="metadata-content"
      [ngClass]="{ 'mobile-width-metadata': isMobile }"
      cdkScrollable
    >
      <div class="metadata-overview">
        <div class="py-4 px-4 container-fluid main-div">
          <div class="mb-4 overview-grid">
            <div #leftSection class="mx-1 left-section" id="data">
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">Scientific Focus</div>
                <div class="content font-family-inter-light">
                  {{
                    recordData && recordData.scientific_focus
                      ? recordData.scientific_focus
                      : "-"
                  }}
                </div>
              </div>
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">Agency</div>
                <div class="content font-family-inter-light">
                  {{
                    recordData && recordData.agency ? recordData.agency : "-"
                  }}
                </div>
              </div>
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">Repository</div>
                <div
                  class="content font-family-inter-light new-line-for-delimiter-items"
                  *ngIf="
                    recordData &&
                    recordData.repository &&
                    recordData.repository.split(';')
                  "
                >
                  {{
                    recordData && recordData.repository.split(";").join("\n")
                  }}
                </div>
                <div
                  class="content font-family-inter-light"
                  *ngIf="
                    recordData &&
                    recordData.repository &&
                    !recordData.repository.split(';')
                  "
                >
                  {{ recordData?.repository }}
                </div>
                <div
                  class="content font-family-inter-light"
                  *ngIf="recordData && !recordData.repository"
                >
                  -
                </div>
              </div>
              <div class="mt-2 px-2 py-2 right-item">
                <div class="title">Persistent Identifier</div>
                <div class="content font-family-inter-light">
                  {{
                    recordData && recordData.persistent_id
                      ? recordData.persistent_id
                      : "-"
                  }}
                </div>
              </div>
            </div>
            <div #rightSection class="mx-1 right-section">
              <div
                class="left-item px-2 py-2"
                id="description"
                #rightSectionTitle
              >
                <div #rightSectionTitle class="title">Description</div>
                <ng-container *ngIf="previewType === 'expanded'">
                  <ng-container *ngIf="recordData.data_product_desc">
                    <ng-container
                      *ngIf="isDescriptionHTML(recordData.data_product_desc)"
                    >
                      <div *ngIf="showEllipsis; else noEllipsis">
                        <div
                          #rightSectionContent
                          class="content font-family-public-sans-regular pb-2"
                        >
                          <div
                            *nestedEllipsis="true; indicator: moreAnchor"
                            [innerHTML]="recordData.data_product_desc"
                            (nestedEllipsisChange)="check($event)"
                          ></div>
                          <ng-template #moreAnchor>
                            <div
                              class="read-less"
                              (click)="
                                expandForReadMore(); showEllipsis = false
                              "
                            >
                              ...Read More
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <ng-template #noEllipsis>
                        <div #rightSectionContent>
                          <div
                            class="content font-family-public-sans-regular pb-2"
                            [innerHTML]="recordData.data_product_desc"
                          ></div>
                          <div class="read-less" (click)="showEllipsis = true">
                            <span class="read-less-span"> Read Less </span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                    <div
                      *ngIf="!isDescriptionHTML(recordData.data_product_desc)"
                      #rightSectionContent
                      ellipsis="...Read More"
                      [ellipsis-content]="recordData.data_product_desc"
                      (ellipsis-click-more)="expandForReadMore()"
                      class="content font-family-public-sans-regular pb-2"
                    ></div>
                  </ng-container>
                  <div
                    *ngIf="!recordData.data_product_desc"
                    #rightSectionContent
                    class="content font-family-public-sans-regular pb-2"
                  >
                    -
                  </div>
                  <ng-container
                    *ngIf="!isDescriptionHTML(recordData.data_product_desc)"
                  >
                    <div
                      class="read-less"
                      *ngIf="expandedText"
                      (click)="initialOverviewGridHeight()"
                    >
                      <span class="read-less-span"> Read Less </span>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="previewType !== 'expanded'">
                  <div
                    #rightSectionContent
                    ellipsis="...Read More"
                    [ellipsis-content]="recordData.data_product_desc"
                    (ellipsis-click-more)="expandForReadMore()"
                    class="content font-family-public-sans-regular pb-2"
                  ></div>
                  <div
                    class="read-less"
                    *ngIf="expandedText"
                    (click)="initialOverviewGridHeight()"
                  >
                    <span class="read-less-span"> Read Less </span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="overview-meta-group container"
            id="data-information"
            #rightSectionTitle
          >
            <div>
              <div class="mb-3 row">
                <div class="col-sm-6" id="dataFormat-accessConstraints">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Data Format
                  </p>
                  <div
                    class="my-1 font-family-inter-light overview-meta-content new-line-for-delimiter-items"
                    *ngIf="
                      recordData &&
                      recordData.dataformat &&
                      recordData.dataformat.split(';')
                    "
                  >
                    {{
                      recordData &&
                        recordData.dataformat
                          .split(";")
                          .join("\r\n")
                          .split(",")
                          .join("\r\n")
                    }}
                  </div>
                  <p
                    class="my-1 font-family-inter-light overview-meta-content"
                    *ngIf="
                      recordData &&
                      recordData.dataformat &&
                      !recordData.dataformat.split(';')
                    "
                  >
                    {{ recordData && recordData.dataformat }}
                  </p>
                  <p
                    class="my-1 font-family-inter-light overview-meta-content"
                    *ngIf="recordData && !recordData.dataformat"
                  >
                    -
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Access Constraints
                  </p>
                  <ng-container *ngIf="accessConstraintData">
                    <div
                      *ngIf="accessConstraintData.Description"
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      <div
                        [innerHTML]="
                          accessConstraintData.Description | linkDetector
                        "
                      ></div>
                    </div>
                    <!-- SPASE -->
                    <p
                      *ngIf="!accessConstraintData.Description"
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      {{ accessConstraintData }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="!accessConstraintData">
                    <p
                      class="my-1 font-family-inter-light overview-meta-content"
                    >
                      -
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="mb-3 row" id="dataProcLevel-useConstraints">
                <div class="col-sm-6">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Data Processing Level
                  </p>
                  <p class="my-1 font-family-inter-light overview-meta-content">
                    {{
                      recordData && recordData.data_process_level
                        ? recordData.data_process_level
                        : "-"
                    }}
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Use Constraints
                  </p>
                  <p class="my-1 font-family-inter-light overview-meta-content">
                    <ng-container *ngIf="useConstraintData">
                      <ng-container
                        *ngIf="useConstraintData.Description"
                        class="value"
                      >
                        <div
                          [innerHTML]="
                            useConstraintData.Description | linkDetector
                          "
                        ></div>
                      </ng-container>
                      <!-- SPASE -->
                      <ng-container
                        *ngIf="
                          !useConstraintData.Description &&
                          !useConstraintData.LicenseURL
                        "
                      >
                        {{ useConstraintData }}
                      </ng-container>
                      <ng-container *ngIf="useConstraintData.LicenseURL">
                        <p
                          class="my-1 font-family-inter-light overview-meta-content"
                        >
                          {{ useConstraintData.LicenseURL.Name + ":" }}
                        </p>
                        <a
                          target="_blank"
                          class="uc-link"
                          [href]="useConstraintData.LicenseURL.Linkage"
                        >
                          {{ useConstraintData.LicenseURL.Linkage }}</a
                        >
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!useConstraintData">
                      <p
                        class="my-1 font-family-inter-light overview-meta-content"
                      >
                        -
                      </p>
                    </ng-container>
                  </p>
                </div>
              </div>
              <div class="mb-3 row" id="dataProcLevelDesc-version">
                <div class="col-sm-6">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Data Processing Level Description
                  </p>
                  <p
                    class="my-1 font-family-inter-light overview-meta-content"
                    *ngIf="recordData && recordData.data_process_level_desc"
                    [innerHTML]="
                      recordData.data_process_level_desc | linkDetector
                    "
                  ></p>
                  <p
                    class="my-1 font-family-inter-light overview-meta-content"
                    *ngIf="recordData && !recordData.data_process_level_desc"
                  >
                    -
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="my-1 overview-meta-title font-family-rajdhani-bold">
                    Version
                  </p>
                  <p class="my-1 font-family-inter-light overview-meta-content">
                    {{
                      recordData && recordData.version_num
                        ? recordData.version_num
                        : recordData && recordData.data_prod_version
                        ? recordData.data_prod_version
                        : "-"
                    }}
                  </p>
                  <p
                    *ngIf="recordData && recordData.version_desc"
                    class="my-1 font-family-inter-light overview-meta-content"
                  >
                    {{
                      recordData && recordData.version_desc
                        ? recordData.version_desc
                        : "-"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="metadata-details" id="attributes" #rightSectionTitle>
        <div class="py-4 px-4 container">
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Platform
              </p>
              <div
                class="my-1 font-family-inter-light details-meta-content new-line-for-delimiter-items"
                *ngIf="
                  recordData &&
                  recordData.platform &&
                  recordData.platform.split(';')
                "
              >
                {{ recordData && recordData.platform.split(";").join("\n") }}
              </div>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="
                  recordData &&
                  recordData.platform &&
                  !recordData.platform.split(';')
                "
              >
                {{ recordData && recordData.platform }}
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData && !recordData.platform"
              >
                -
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Spatial Bounds Coordinate System
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.spatial_bounds_coord_sys
                    ? recordData.spatial_bounds_coord_sys
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Instrument
              </p>
              <div
                class="my-1 font-family-inter-light details-meta-content new-line-for-delimiter-items"
                *ngIf="
                  recordData &&
                  recordData.instrument &&
                  recordData.instrument.split(';')
                "
              >
                {{ recordData && recordData.instrument.split(";").join("\n") }}
              </div>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="
                  recordData &&
                  recordData.instrument &&
                  !recordData.instrument.split(';')
                "
              >
                {{ recordData && recordData.instrument }}
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData && !recordData.instrument"
              >
                -
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Spatial Bounds
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{ generateSpatialBounds(recordData) }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Data Format
              </p>
              <div
                class="my-1 font-family-inter-light details-meta-content new-line-for-delimiter-items"
                *ngIf="
                  recordData &&
                  recordData.dataformat &&
                  recordData.dataformat.split(';')
                "
              >
                {{
                  recordData &&
                    recordData.dataformat
                      .split(";")
                      .join("\n")
                      .split(",")
                      .join("\r\n")
                }}
              </div>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="
                  recordData &&
                  recordData.dataformat &&
                  !recordData.dataformat.split(';')
                "
              >
                {{ recordData && recordData.dataformat }}
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData && !recordData.dataformat"
              >
                -
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Spatial Resolution
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{ generateSpatialResolution(recordData) }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Data Volume
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.data_volume
                    ? recordData.data_volume
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Temporal Bounds
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{ generateTemporalBounds(recordData) }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Data Processing Level
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.data_process_level
                    ? recordData.data_process_level
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Temporal Resolution
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{ generateTemporalResolution(recordData) }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Data Processing Level Description
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.data_process_level_desc
                    ? recordData.data_process_level_desc
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Operation Wavelength / Spectral Band
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.operation_wav_spectral
                    ? recordData.operation_wav_spectral
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Science Keywords
              </p>
              <div class="my-1 font-family-inter-light details-meta-content">
                <ng-container *ngIf="scienceKeywordsArr.length > 0">
                  <ng-container *ngIf="scienceKeywordsArr.length === 1">
                    <p
                      class="my-1 font-family-inter-light details-meta-content"
                    >
                      {{ scienceKeywordsArr[0] }}
                    </p>
                  </ng-container>
                  <ng-container *ngIf="scienceKeywordsArr.length > 1">
                    <ul
                      *ngFor="let scienceKeyword of scienceKeywordsArr"
                      class="my-1"
                    >
                      <li>{{ scienceKeyword }}</li>
                    </ul>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="scienceKeywordsArr.length == 0">
                  <p>-</p>
                </ng-container>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'spase'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Observed Region
              </p>
              <div
                class="my-1 font-family-inter-light details-meta-content new-line-for-delimiter-items"
                *ngIf="
                  recordData &&
                  recordData.spase_observed_region &&
                  recordData.spase_observed_region.split(';')
                "
              >
                {{
                  recordData &&
                    recordData.spase_observed_region.split(";").join("\r\n")
                }}
              </div>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="
                  recordData &&
                  recordData.spase_observed_region &&
                  !recordData.spase_observed_region.split(';')
                "
              >
                {{ recordData && recordData.spase_observed_region }}
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData && !recordData.spase_observed_region"
              >
                -
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'pds'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                PDS LID
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.pds_lid ? recordData.pds_lid : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'pds'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                PDS Bundle
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.pds_bundle
                    ? recordData.pds_bundle
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'pds'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                PDS Collection
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.pds_collection
                    ? recordData.pds_collection
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'astrophysics'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                IVO ID
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{ recordData && recordData.ivo_id ? recordData.ivo_id : "-" }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'astrophysics'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Capability Type
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.cap_type ? recordData.cap_type : "-"
                }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6" *ngIf="dataSource === 'osdr'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                OSDR Identifier
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.bps_osdr_id
                    ? recordData.bps_osdr_id
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'osdr'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Factor
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.bps_factor
                    ? recordData.bps_factor
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6" *ngIf="dataSource === 'osdr'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Organism
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData?.related_urls_4"
              >
                <a
                  target="_blank"
                  class="uc-link"
                  [href]="recordData?.related_urls_4"
                >
                  {{
                    recordData && recordData.bps_organism
                      ? recordData.bps_organism
                      : "-"
                  }}
                </a>
              </p>
              <p
                class="my-1 font-family-inter-light details-meta-content"
                *ngIf="recordData?.bps_organism && !recordData?.related_urls_4"
              >
                {{
                  recordData && recordData.bps_organism
                    ? recordData.bps_organism
                    : "-"
                }}
              </p>
            </div>
            <div class="col-sm-6" *ngIf="dataSource === 'osdr'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Funding
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.bps_funding
                    ? recordData.bps_funding
                    : "-"
                }}
              </p>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-sm-6" *ngIf="dataSource === 'osdr'">
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                Data Product Type
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                {{
                  recordData && recordData.doctype ? recordData.doctype : "-"
                }}
              </p>
            </div>
            <div
              class="col-sm-6"
              *ngIf="
                dataSource === 'osdr' && recordData && recordData.download_url
              "
            >
              <p class="my-1 details-meta-title font-family-rajdhani-bold">
                OSDR Dataset Landing Page
              </p>
              <p class="my-1 font-family-inter-light details-meta-content">
                <a
                  target="_blank"
                  class="uc-link"
                  [href]="recordData.download_url"
                >
                  {{
                    recordData && recordData.download_url
                      ? recordData.download_url
                      : "-"
                  }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="metadata-links mb-12" #rightSectionTitle id="links">
        <div class="title links-title">Links</div>
        <div
          class="py-3 container-fluid main-div"
          [ngClass]="{ 'main-div-mobile': isMobile }"
        >
          <ng-container *ngIf="dataSource == 'cmr'">
            <ng-container
              *ngIf="
                recordData && recordData.urls && recordData.urls.length > 0
              "
            >
              <div
                class="link-container-parent"
                *ngFor="let link of parsedLinksData | keyvalue; let i = index"
              >
                <!-- Iterate over each item in the link.value array -->
                <div
                  *ngFor="let item of link.value; let j = index"
                  [ngStyle]="{
                    'background-color':
                      (i + j) % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                  }"
                  class="link-container"
                >
                  <!-- Display the key once, and display items underneath it -->
                  <div
                    class="url-type"
                    [ngClass]="{
                      'url-type-mobile': isMobile
                    }"
                  >
                    <span> {{ link.key.replace("URL", "") | uppercase }}</span>
                  </div>
                  <!-- Link information -->
                  <div
                    class="link-information"
                    [ngClass]="{ 'link-information-mobile': isMobile }"
                  >
                    <img
                      src="assets/img/scix-link.svg"
                      alt="link_img"
                      width="18px"
                      height="18px"
                    />
                    <a
                      target="_blank"
                      [href]="item.URL"
                      (click)="MetaDataLinkClick(item.URL, link)"
                      style="margin-left: 8px; text-align: left; flex-grow: 1"
                    >
                      <!-- For Description -->
                      <span
                        *ngIf="item.Description"
                        [ngClass]="{
                          'desc-mobile': isMobile,
                          'multi-line-ellipsis': true
                        }"
                        [sqTooltip]="item.Description"
                      >
                        {{ item.Description }}
                      </span>

                      <!-- For URL if Description is not available -->
                      <span
                        *ngIf="!item.Description"
                        [ngClass]="{
                          'link-url-mobile': isMobile,
                          'multi-line-ellipsis-link': true
                        }"
                        [sqTooltip]="item.URL"
                      >
                        {{ item.URL }}
                      </span>
                    </a>
                  </div>

                  <!-- View link -->
                  <div
                    class="view-link a"
                    *ngIf="!isMobile"
                    (click)="MetaDataLinkClick(item.URL, link)"
                  >
                    <span>View</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                recordData &&
                recordData.urls &&
                parsedS3LinksData &&
                parsedS3LinksData.length > 0
              "
            >
              <ng-container
                *ngIf="parsedS3LinksData && parsedS3LinksData.length > 0"
              >
                <div *ngFor="let link of parsedS3LinksData; let i = index">
                  <div
                    [ngClass]="{
                      'link-container-cloud-access-mobile': isMobile
                    }"
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container-cloud-access py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- Label -->
                      <span class="font-family-public-sans-regular"
                        >CLOUD ACCESS S3 INFORMATION</span
                      >
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-section-cloud-access">
                        <!-- Link Section -->
                        <div class="link-content-cloud-access">
                          <!-- Image -->
                          <img
                            src="assets/img/scix-link.svg"
                            alt="link_img"
                            width="18px"
                            height="18px"
                          />
                          <a
                            class="metadata-link"
                            target="_blank"
                            [href]="link.S3CredentialsAPIEndpoint"
                            (click)="
                              MetaDataLinkClick(
                                link.S3CredentialsAPIEndpoint,
                                'CLOUD ACCESS S3 INFORMATION'
                              )
                            "
                            style="margin-left: 8px; text-align: left"
                          >
                            <div
                              class="font-family-inter-light url-description"
                            >
                              <span
                                [ngClass]="{ 'link-url-mobile': isMobile }"
                                >{{ link.S3CredentialsAPIEndpoint }}</span
                              >
                            </div>
                          </a>
                        </div>

                        <!-- Information Section (Region and Bucket) -->
                        <div
                          class="content-container-cloud-access"
                          [ngClass]="{
                            'content-container-cloud-access-mobile': isMobile
                          }"
                          *ngIf="
                            link.Region || link.S3BucketAndObjectPrefixNames
                          "
                        >
                          <div class="cloud-access-region-container">
                            <div
                              class="url-description-label font-family-rajdhani-regular"
                            >
                              REGION
                            </div>
                            <div
                              class="font-family-inter-light url-description"
                            >
                              {{ link.Region }}
                            </div>
                          </div>

                          <div class="cloud-access-s3bucket-container">
                            <div
                              class="url-description-label font-family-rajdhani-regular"
                            >
                              S3 BUCKET AND OBJECT PREFIX NAMES
                            </div>
                            <div
                              class="font-family-inter-light url-description"
                            >
                              {{ link.S3BucketAndObjectPrefixNames }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Last item (right-aligned) -->
                    <div
                      class="view-link"
                      *ngIf="!isMobile"
                      (click)="
                        MetaDataLinkClick(
                          link.S3CredentialsAPIEndpoint,
                          'CLOUD ACCESS S3 INFORMATION'
                        )
                      "
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div
              *ngIf="
                recordData &&
                (!recordData.urls || recordData.urls.length <= 0) &&
                parsedS3LinksData &&
                parsedS3LinksData.length <= 0
              "
              class="no-data-text-container d-flex flex-column justify-content-center"
            >
              <h4>No links Available</h4>
            </div>
          </ng-container>
          <ng-container *ngIf="dataSource == 'spase'">
            <ng-container
              *ngIf="
                recordData &&
                ((recordData.related_urls_1 &&
                  recordData.related_urls_1.length > 0) ||
                  (recordData.related_urls_2 &&
                    recordData.related_urls_2.length > 0) ||
                  (recordData.related_urls_3 &&
                    recordData.related_urls_3.length > 0))
              "
            >
              <div
                *ngIf="
                  recordData.related_urls_1 &&
                  recordData.related_urls_1.length > 0
                "
              >
                <div
                  *ngFor="let link of recordData.related_urls_1; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- DATA ACCESS Label -->
                      <span class="mx-2">DATA ACCESS</span>
                      <!-- Image -->
                      <img
                        src="assets/img/scix-link.svg"
                        alt="link_img"
                        width="18px"
                        height="18px"
                        style="margin-left: 8px"
                      />
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-content">
                        <a
                          class="metadata-link-spase font-family-inter-light"
                          target="_blank"
                          [href]="link"
                          (click)="MetaDataLinkClick(link, 'DATA ACCESS')"
                          style="margin-left: 8px; text-align: left"
                        >
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >

                          <span
                            *ngIf="!link.Description"
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            >{{ link }}</span
                          >
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      class="view-link"
                      *ngIf="!isMobile"
                      (click)="MetaDataLinkClick(link, 'DATA ACCESS')"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  recordData.related_urls_2 &&
                  recordData.related_urls_2.length > 0
                "
              >
                <div
                  *ngFor="let link of recordData.related_urls_2; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- INFORMATION Label -->
                      <span class="mx-2">INFORMATION</span>
                      <!-- Image -->
                      <img
                        src="assets/img/scix-link.svg"
                        alt="link_img"
                        width="18px"
                        height="18px"
                        style="margin-left: 8px"
                      />
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-content">
                        <a
                          class="metadata-link-spase"
                          target="_blank"
                          [href]="link"
                          (click)="MetaDataLinkClick(link, 'INFORMATION')"
                          style="margin-left: 8px"
                        >
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >
                          <span
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            *ngIf="!link.Description"
                            >{{ link }}</span
                          >
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      class="view-link"
                      *ngIf="!isMobile"
                      (click)="MetaDataLinkClick(link, 'INFORMATION')"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  recordData.related_urls_3 &&
                  recordData.related_urls_3.length > 0
                "
              >
                <div
                  *ngFor="let link of recordData.related_urls_3; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- DATASET LANDING PAGE Label -->
                      <span class="mx-2">DATASET LANDING PAGE</span>
                      <!-- Image -->
                      <img
                        src="assets/img/scix-link.svg"
                        alt="link_img"
                        width="18px"
                        height="18px"
                        style="margin-left: 8px"
                      />
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-content">
                        <a
                          class="metadata-link-spase"
                          target="_blank"
                          [href]="link"
                          (click)="
                            MetaDataLinkClick(link, 'DATASET LANDING PAGE')
                          "
                          style="margin-left: 8px; text-align: left"
                        >
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >
                          <span
                            *ngIf="!link.Description"
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            >{{ link }}</span
                          >
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      class="view-link"
                      *ngIf="!isMobile"
                      (click)="MetaDataLinkClick(link, 'DATASET LANDING PAGE')"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="
                !recordData.related_urls_1 &&
                !recordData.related_urls_2 &&
                !recordData.related_urls_3
              "
              class="no-data-text-container d-flex flex-column justify-content-center"
            >
              <h4>No links Available</h4>
            </div>
          </ng-container>
          <ng-container *ngIf="dataSource == 'pds'">
            <ng-container *ngIf="recordData && recordData.related_urls_1">
              <div
                *ngFor="let link of recordData.related_urls_1; let i = index"
              >
                <div
                  [ngStyle]="{
                    'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                  }"
                  class="link-container py-1 mx-2 border-for-links"
                >
                  <!-- First item (left-aligned) -->
                  <div
                    class="url-type"
                    [ngClass]="{
                      'url-type-mobile': isMobile
                    }"
                  >
                    <!-- Image -->
                    <img
                      src="assets/img/scix-link.svg"
                      alt="link_img"
                      width="18px"
                      height="18px"
                      style="margin-left: 8px"
                    />
                  </div>
                  <!-- Middle item (center-aligned) -->
                  <div
                    class="link-information"
                    [ngClass]="{ 'link-information-mobile': isMobile }"
                  >
                    <div class="link-content">
                      <a
                        class="metadata-link-spase"
                        target="_blank"
                        [href]="link"
                        (click)="MetaDataLinkClick(link, '')"
                        style="margin-left: 8px; text-align: left"
                      >
                        <div class="font-family-inter-light url-description">
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >
                          <span
                            *ngIf="!link.Description"
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            >{{ link }}</span
                          >
                        </div>
                      </a>
                    </div>
                  </div>
                  <!-- Last item (right-aligned) -->
                  <div
                    class="view-link"
                    *ngIf="!isMobile"
                    (click)="MetaDataLinkClick(link, '')"
                  >
                    <span>View</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="!recordData.related_urls_1"
              class="no-data-text-container d-flex flex-column justify-content-center"
            >
              <h4>No links Available</h4>
            </div>
          </ng-container>
          <ng-container *ngIf="dataSource == 'astrophysics'">
            <ng-container *ngIf="recordData && recordData.related_urls_1">
              <div
                *ngFor="let link of recordData.related_urls_1; let i = index"
              >
                <div
                  [ngStyle]="{
                    'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                  }"
                  class="link-container py-1 mx-2 border-for-links"
                >
                  <!-- First item (left-aligned) -->
                  <div
                    class="url-type"
                    [ngClass]="{
                      'url-type-mobile': isMobile
                    }"
                  >
                    <!-- INFORMATION Label -->
                    <span class="font-family-rajdhani-semibold"
                      >INFORMATION</span
                    >
                    <!-- Image -->
                    <img
                      src="assets/img/scix-link.svg"
                      alt="link_img"
                      width="18px"
                      height="18px"
                      style="margin-left: 8px"
                    />
                  </div>
                  <!-- Middle item (center-aligned) -->
                  <div
                    class="link-information"
                    [ngClass]="{ 'link-information-mobile': isMobile }"
                  >
                    <div class="link-content">
                      <a
                        class="metadata-link-spase"
                        target="_blank"
                        [href]="link"
                        (click)="MetaDataLinkClick(link, 'INFORMATION')"
                        style="margin-left: 8px; text-align: left"
                      >
                        <div class="font-family-inter-light url-description">
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >
                          <span
                            *ngIf="!link.Description"
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            >{{ link }}</span
                          >
                        </div>
                      </a>
                    </div>
                  </div>
                  <!-- Last item (right-aligned) -->
                  <div
                    (click)="MetaDataLinkClick(link, 'INFORMATION')"
                    class="view-link"
                    *ngIf="!isMobile"
                  >
                    <span>View</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="recordData && recordData.related_urls_2">
              <div
                *ngFor="let link of recordData.related_urls_2; let i = index"
              >
                <div
                  [ngStyle]="{
                    'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                  }"
                  class="link-container py-1 mx-2 border-for-links"
                >
                  <!-- First item (left-aligned) -->
                  <div
                    class="url-type"
                    [ngClass]="{
                      'url-type-mobile': isMobile
                    }"
                  >
                    <!-- DATA ACCESS Label -->
                    <span class="font-family-rajdhani-semibold"
                      >DATA ACCESS</span
                    >
                    <!-- Image -->
                    <img
                      src="assets/img/scix-link.svg"
                      alt="link_img"
                      width="18px"
                      height="18px"
                      style="margin-left: 8px"
                    />
                  </div>
                  <!-- Middle item (center-aligned) -->
                  <div
                    class="link-information"
                    [ngClass]="{ 'link-information-mobile': isMobile }"
                  >
                    <div class="link-content">
                      <a
                        class="metadata-link-spase"
                        target="_blank"
                        [href]="link"
                        (click)="MetaDataLinkClick(link, 'DATA ACCESS')"
                        style="margin-left: 8px; text-align: left"
                      >
                        <div class="font-family-inter-light url-description">
                          <span
                            *ngIf="link.Description"
                            [ngClass]="{
                              'desc-mobile': isMobile,
                              'multi-line-ellipsis': true
                            }"
                            [sqTooltip]="link.Description"
                            >{{ link.Description }}</span
                          >
                          <span
                            *ngIf="!link.Description"
                            [ngClass]="{
                              'link-url-mobile': isMobile,
                              'multi-line-ellipsis-link': true
                            }"
                            [sqTooltip]="link"
                            >{{ link }}</span
                          >
                        </div>
                      </a>
                    </div>
                  </div>
                  <!-- Last item (right-aligned) -->
                  <div
                    (click)="MetaDataLinkClick(link, 'DATA ACCESS')"
                    class="view-link"
                    *ngIf="!isMobile"
                  >
                    <span>View</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div
              *ngIf="!recordData.related_urls_1 && !recordData.related_urls_2"
              class="no-data-text-container d-flex flex-column justify-content-center"
            >
              <h4>No links Available</h4>
            </div>
          </ng-container>
          <ng-container *ngIf="dataSource == 'osdr'">
            <ng-container
              *ngIf="
                recordData &&
                ((recordData.related_urls_1 &&
                  recordData.related_urls_1.length > 0) ||
                  (recordData.related_urls_2 &&
                    recordData.related_urls_2.length > 0) ||
                  (recordData.related_urls_3 &&
                    recordData.related_urls_3.length > 0))
              "
            >
              <ng-container *ngIf="recordData && recordData.related_urls_1">
                <div
                  *ngFor="let link of recordData.related_urls_1; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- DATA SOURCE Label -->
                      <span class="font-family-rajdhani-semibold"
                        >DATA SOURCE</span
                      >
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-content">
                        <!-- Image -->
                        <img
                          src="assets/img/scix-link.svg"
                          alt="link_img"
                          width="18px"
                          height="18px"
                          style="margin-left: 8px"
                        />
                        <a
                          class="metadata-link-spase"
                          target="_blank"
                          [href]="link"
                          (click)="MetaDataLinkClick(link, 'DATA SOURCE')"
                          style="margin-left: 8px; text-align: left"
                        >
                          <div class="font-family-inter-light url-description">
                            <span
                              *ngIf="link.Description"
                              [ngClass]="{
                                'desc-mobile': isMobile,
                                'multi-line-ellipsis': true
                              }"
                              [sqTooltip]="link.Description"
                              >{{ link.Description }}</span
                            >
                            <span
                              *ngIf="!link.Description"
                              [ngClass]="{
                                'link-url-mobile': isMobile,
                                'multi-line-ellipsis-link': true
                              }"
                              [sqTooltip]="link"
                              >{{ link }}</span
                            >
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      (click)="MetaDataLinkClick(link, 'DATA SOURCE')"
                      class="view-link"
                      *ngIf="!isMobile"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="recordData && recordData.related_urls_2">
                <div
                  *ngFor="let link of recordData.related_urls_2; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- PROJECT Label -->
                      <span class="font-family-rajdhani-semibold">PROJECT</span>
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div class="link-content">
                        <!-- Image -->
                        <img
                          src="assets/img/scix-link.svg"
                          alt="link_img"
                          width="18px"
                          height="18px"
                          style="margin-left: 8px"
                        />
                        <a
                          class="metadata-link-spase"
                          target="_blank"
                          [href]="link"
                          (click)="MetaDataLinkClick(link, 'PROJECT')"
                          style="margin-left: 8px; text-align: left"
                        >
                          <div class="font-family-inter-light url-description">
                            <span
                              *ngIf="link.Description"
                              [ngClass]="{
                                'desc-mobile': isMobile,
                                'multi-line-ellipsis': true
                              }"
                              [sqTooltip]="link.Description"
                              >{{ link.Description }}</span
                            >
                            <span
                              *ngIf="!link.Description"
                              [ngClass]="{
                                'link-url-mobile': isMobile,
                                'multi-line-ellipsis-link': true
                              }"
                              [sqTooltip]="link"
                              >{{ link }}</span
                            >
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      (click)="MetaDataLinkClick(link, 'PROJECT')"
                      class="view-link"
                      *ngIf="!isMobile"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="recordData && recordData.related_urls_3">
                <div
                  *ngFor="let link of recordData.related_urls_3; let i = index"
                >
                  <div
                    [ngStyle]="{
                      'background-color': i % 2 === 0 ? '#F3F5F9' : '#FFFFFF'
                    }"
                    class="link-container py-1 mx-2 border-for-links"
                  >
                    <!-- First item (left-aligned) -->
                    <div
                      class="url-type"
                      [ngClass]="{
                        'url-type-mobile': isMobile
                      }"
                    >
                      <!-- MISSION Label -->
                      <span class="font-family-rajdhani-semibold">MISSION</span>
                    </div>
                    <!-- Middle item (center-aligned) -->
                    <div
                      class="link-information"
                      [ngClass]="{ 'link-information-mobile': isMobile }"
                    >
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: flex-start;
                        "
                      >
                        <!-- Image -->
                        <img
                          src="assets/img/scix-link.svg"
                          alt="link_img"
                          width="18px"
                          height="18px"
                          style="margin-left: 8px"
                        />
                        <a
                          class="metadata-link-spase"
                          target="_blank"
                          [href]="link"
                          (click)="MetaDataLinkClick(link, 'MISSION')"
                          style="margin-left: 8px; text-align: left"
                        >
                          <div class="font-family-inter-light url-description">
                            <span
                              *ngIf="link.Description"
                              [ngClass]="{
                                'desc-mobile': isMobile,
                                'multi-line-ellipsis': true
                              }"
                              [sqTooltip]="link.Description"
                              >{{ link.Description }}</span
                            >
                            <span
                              *ngIf="!link.Description"
                              class="link-url"
                              [ngClass]="{
                                'link-url-mobile': isMobile,
                                'multi-line-ellipsis-link': true
                              }"
                              [sqTooltip]="link"
                              >{{ link }}</span
                            >
                          </div>
                        </a>
                      </div>
                    </div>
                    <!-- Last item (right-aligned) -->
                    <div
                      (click)="MetaDataLinkClick(link, 'MISSION')"
                      class="view-link"
                      *ngIf="!isMobile"
                    >
                      <span>View</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div
              *ngIf="
                !recordData.related_urls_1 &&
                !recordData.related_urls_2 &&
                !recordData.related_urls_3
              "
              class="no-data-text-container d-flex flex-column justify-content-center"
            >
              <h4>No links Available</h4>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="metadata-list" *ngIf="!isMobile">
      <div class="title" (click)="triggerScroll('data')">Table of Contents</div>
      <ul class="table-of-contents side-nav">
        <li>
          <a
            (click)="triggerScroll('data')"
            class="list-item font-family-rajdhani-bold"
            >Overview</a
          >
          <ul>
            <li
              [class.active]="activeSection === 'data'"
              class="list-item font-family-rajdhani-medium sub-item"
            >
              <a (click)="triggerScroll('data')">Data</a>
            </li>
            <li
              [class.active]="activeSection === 'description'"
              class="list-item font-family-rajdhani-medium sub-item"
            >
              <a (click)="triggerScroll('description')">Description</a>
            </li>
            <li
              [class.active]="activeSection === 'data-information'"
              class="list-item font-family-rajdhani-medium sub-item"
            >
              <a (click)="triggerScroll('data-information')"> Information</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            class="list-item font-family-rajdhani-bold"
            (click)="triggerScroll('attributes')"
            >Details</a
          >
          <ul>
            <li
              [class.active]="activeSection === 'attributes'"
              class="list-item font-family-rajdhani-medium sub-item"
            >
              <a (click)="triggerScroll('attributes')">Attributes</a>
            </li>
          </ul>
        </li>
        <li>
          <a
            class="list-item font-family-rajdhani-bold"
            (click)="triggerScroll('links')"
            >Links</a
          >
          <ul>
            <li
              [class.active]="activeSection === 'links'"
              class="list-item font-family-rajdhani-medium sub-item"
            >
              <a (click)="triggerScroll('links')">Metadata Links</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

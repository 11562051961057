<!-- Scaled-down preview -->
<app-sde-facet-card
  #previewFacetCard
  *ngIf="openedDoc"
  [type]="openedDoc.isMetadataViewer ? 'metadata-viewer' : 'preview'"
  [record]="openedDoc"
  [collapsible]="false"
  [actionsSize]="''"
  (closeDocument)="closeDocument()"
  (expandPreview)="expandDocument(openedDoc)"
  (toggleEntities)="toggleEntityHighlighting($event)"
  (toggleExtracts)="toggleExtractsHighlighting($event)"
  (copyLinkToDocument)="copyUrl(openedDoc, true)"
  (downloadDocument)="downloadDocument(openedDoc)"
>
  <app-sde-preview
    #sdePreview
    [previewType]="'SHORT'"
    [id]="openedDoc.id"
    [highlightColors]="previewHighlights"
    [highlightEntities]="entityHighlightFlag"
    [highlightExtracts]="extractsHighlightFlag"
    [preferenceName]="'mini-preview'"
    [scale]="0.7"
  >
  </app-sde-preview>
</app-sde-facet-card>

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { PrincipalWebService } from "@sinequa/core/web-services";
import { throwError } from "rxjs/internal/observable/throwError";
import { environment } from "../../../environments/environment";
import googleAnalyticsConstants from "../../analytics/google-analytics.constant";
import { NotificationsComponent } from "../../home/notifications/notifications.component";
import { CommonService } from "../../services/common.service";
import { HeaderService } from "../../services/header.service";

@Component({
  selector: "app-content-curation-page",
  templateUrl: "./content-curation-page.component.html",
  styleUrls: ["./content-curation-page.component.scss"],
})
export class ContentCurationPageComponent implements OnInit {
  contentCurationForm: FormGroup;
  isScientificDropdownOpen = false;
  isSourceTypeDropdownOpen = false;
  scientificDropdownItems: any[] = [
    {
      name: "Astrophysics",
      icon: "assets/img/astrophysics-image.svg",
    },
    {
      name: "Biological & Physical Sciences",
      icon: "assets/img/biophysical-image.svg",
    },
    {
      name: "Earth Science",
      icon: "assets/img/earth-science.svg",
    },
    {
      name: "Heliophysics",
      icon: "assets/img/heliophysics-image.svg",
    },
    {
      name: "Planetary Science",
      icon: "assets/img/planetary-image.svg",
    },
  ];
  dataValuesItems = [
    "Data",
    "Images",
    "Documentation",
    "Software and Tools ",
    "Mission and Instruments",
  ]; // Replace with your list of items
  selectedItemsInScientificDropdown: string[] = [];
  selectedItemsInSourceTypeDropdown: string[] = [];
  contentCurationFormSubmitted: boolean = false;
  showSourceTypeDropdownError: boolean = false;
  showScientificDropdownError: boolean = false;
  userFullName: string | undefined = "";
  notifications: [];

  constructor(
    private commonService: CommonService,
    private titleService: Title,
    private principalService: PrincipalWebService,
    private headerSrv: HeaderService,
    private appService: AppService,
    public loginService: LoginService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.contentCurationRequest
    );
    this.principalService.events.subscribe((event) => {
      if (this.principalService.principal?.fullName != undefined) {
        this.userFullName =
          environment.title + "-" + this.principalService.principal?.fullName;
      } else this.userFullName = "Waiting for Authentication...";
    });

    this.loginService.events.subscribe((event) => {
      if (!this.headerSrv.notificationShown && this.appService.app) {
        this.showNotifications();
        this.headerSrv.notificationShown = !this.headerSrv.notificationShown;
      }
    });

    this.contentCurationForm = new FormGroup({
      firstName: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      scientificFocusValues: new FormControl(""),
      dataItemsValues: new FormControl(""),
      sourceLink: new FormControl("", Validators.required),
      additionalInfo: new FormControl(""),
    });
  }

  get f() {
    return this.contentCurationForm.controls;
  }

  showNotifications() {
    this.notifications = this.appService.app?.data?.notifications as any;
    if (this.notifications != null) {
      this.notifications?.forEach(
        (app: { id: number; title: string; details: string }, index: any) => {
          this.openDialog(app.title, app.details);
        }
      );
    }
  }

  openDialog(title, details) {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      hasBackdrop: true,
      width: "450px",
      height: "200px",
      position: {
        top: "50vh",
        left: "36vw",
      },
      panelClass: "makeItMiddle",
      autoFocus: true,
      disableClose: true,
      backdropClass: "custom-dialog-backdrop-class",
      data: {
        title: title,
        details: details,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSubmitContentCurationForm() {
    if (this.contentCurationForm.invalid) {
      // Your form submission logic goes here
      this.contentCurationForm.markAllAsTouched();
    }
    // Check if the source type dropdown has no selected items
    if (this.selectedItemsInSourceTypeDropdown.length === 0) {
      this.showSourceTypeDropdownError = true;
    } else {
      this.showSourceTypeDropdownError = false;
    }

    // Check if the scientific focus dropdown has no selected items
    if (this.selectedItemsInScientificDropdown.length === 0) {
      // Set the error flag for scientific focus dropdown
      this.showScientificDropdownError = true;
    } else {
      // Reset the error flag for scientific focus dropdown
      this.showScientificDropdownError = false;
    }

    // If either dropdown has an error, stop form submission
    if (this.showSourceTypeDropdownError || this.showScientificDropdownError) {
      return;
    }
    // for setting the value
    this.contentCurationForm.controls["dataItemsValues"].setValue(
      this.selectedItemsInSourceTypeDropdown
    );
    this.contentCurationForm.controls["scientificFocusValues"].setValue(
      this.selectedItemsInScientificDropdown
    );

    if (
      this.contentCurationForm.invalid ||
      this.showSourceTypeDropdownError ||
      this.showScientificDropdownError
    ) {
      this.contentCurationFormSubmitted = false;
    } else {
      let request = {
        name: this.contentCurationForm.value.firstName,
        email: this.contentCurationForm.value.email,
        scientific_focus:
          this.contentCurationForm.value.scientificFocusValues.toString(),
        data_type: this.contentCurationForm.value.dataItemsValues.toString(),
        data_link: this.contentCurationForm.value.sourceLink,
        additional_info: this.contentCurationForm.value.additionalInfo,
      };
      this.commonService.submitContentCurationForm(request).subscribe({
        next: (response) => {
          this.contentCurationFormSubmitted = true;
        },
        error: (error) => {
          throwError(() => new Error("Please try again later"));
        },
      });
    }
  }

  ScientificToggleDropdown() {
    this.isScientificDropdownOpen = !this.isScientificDropdownOpen;
  }

  toggleItemScientificDropdown(item: string) {
    if (this.selectedItemsInScientificDropdown.includes(item)) {
      this.selectedItemsInScientificDropdown =
        this.selectedItemsInScientificDropdown.filter((i) => i !== item);
    } else {
      this.selectedItemsInScientificDropdown.push(item);
    }
    this.showScientificDropdownError = false;
  }

  removeItemScientificDropdown(item: string) {
    this.selectedItemsInScientificDropdown =
      this.selectedItemsInScientificDropdown.filter((i) => i !== item);
  }

  SourceTypeToggleDropdown() {
    this.isSourceTypeDropdownOpen = !this.isSourceTypeDropdownOpen;
  }

  toggleItemSourceTypeDropdown(item: string) {
    if (this.selectedItemsInSourceTypeDropdown.includes(item)) {
      this.selectedItemsInSourceTypeDropdown =
        this.selectedItemsInSourceTypeDropdown.filter((i) => i !== item);
    } else {
      this.selectedItemsInSourceTypeDropdown.push(item);
    }
    this.showSourceTypeDropdownError = false;
  }

  removeItemSourceTypeDropdown(item: string) {
    this.selectedItemsInSourceTypeDropdown =
      this.selectedItemsInSourceTypeDropdown.filter((i) => i !== item);
  }
}

import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Inject, Injectable, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { Query } from "@sinequa/core/app-utils";
import { Utils } from "@sinequa/core/base";
import {
  NotificationType,
  NotificationsService,
} from "@sinequa/core/notification";
import {
  Aggregation,
  Record,
  StartConfig,
  START_CONFIG,
} from "@sinequa/core/web-services";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { BehaviorSubject, Subject, Subscription, tap, throwError } from "rxjs";
import { SdeRecord } from "../../model/extended.interface";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { appConfig } from "../app-config/app-config";
import { CopierService } from "./copier.service";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  showDidYouMean: boolean | null = null;
  appLabel: string;
  _loadingFlag: boolean = true;
  searchText: string;
  sciXCount: number;
  resultsCardLoader: boolean;
  selectedScientificFocus: string = "";
  modalRef?: BsModalRef;
  dropdownSubscription: Subscription;
  currentScrollPosition: any;
  contactUsURL = "contact-us-api/";
  contentCurationURL = "content-curation-request-api/";
  focusAreaAggregation: Aggregation | undefined;
  sharedData: any = null;
  mobileModalOpen: boolean = false;
  mobileVideoTime: any = 0;
  secretProdEnvs: string[] = ["dev", "qa", "staging", "lrm-dev", "production"];
  intendedUseDropdownObj: any;
  windowPath: string;

  globalNasaLoader: BehaviorSubject<any> = new BehaviorSubject(false);
  clearInputFieldSource: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  showWalkthroughStep: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isInScix: BehaviorSubject<string> = new BehaviorSubject<string>("");
  isDropdownOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isEJDropdownOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private isSearchWithOriginalTextSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private enableNeuralSearchSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  closeMetadataViewer: Subject<any> = new Subject();
  isFocusDropdownDisabledEvent: Subject<boolean> = new Subject();
  ejSelectedFocusChange: Subject<string> = new Subject<string>();
  currentPageSource: Subject<string> = new Subject<string>(); // for ngx joyride walkthrough SDE , flags checks to start walkthrough on results page else home page
  currentPageSourceEj: Subject<string> = new Subject<string>(); // for ngx joyride walkthrough SDE -EJ, flags checks to start walkthrough on results page else home page
  showMobileFilterButtonValue: Subject<boolean> = new Subject<boolean>();
  projectWalkthroughStep: Subject<any> = new Subject();
  gridColumnApi: Subject<any> = new Subject<any>();
  openVideoFromExternalAction: Subject<any> = new Subject<any>();
  selectAllEntities: Subject<any> = new Subject();

  clearInputField$ = this.clearInputFieldSource.asObservable();
  isSearchWithOriginalText$ =
    this.isSearchWithOriginalTextSource.asObservable();
  enableNeuralSearch$ = this.enableNeuralSearchSubject.asObservable();
  currentPage$ = this.currentPageSource.asObservable();
  currentPageEj$ = this.currentPageSourceEj.asObservable();

  clearFilterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private modalService: BsModalService,
    private previewService: PreviewService,
    private urlCopierService: CopierService,
    private router: Router,
    private httpClient: HttpClient,
    private $gaService: GoogleAnalyticsService,
    private searchService: SearchService,
    private location: Location,
    private notificationsService: NotificationsService,
    @Inject(START_CONFIG) private startConfig: StartConfig
  ) {}

  setSearchWithOriginalTextFlag(value: boolean) {
    this.isSearchWithOriginalTextSource.next(value);
  }

  loadingBetweenComponents(flag: boolean) {
    this._loadingFlag = flag;
  }

  // Neural Search Enable/Disable logic. Set local storage value to persist the setting across sessions.
  toggleNeuralSearch(enabled: boolean): void {
    if (enabled) {
      this.enableNeuralSearchSubject.next(enabled);
      localStorage.setItem("neural-search", "true");
    } else {
      localStorage.setItem("neural-search", "false");
      this.enableNeuralSearchSubject.next(enabled);
    }
  }

  setWalkthroughStepValue(value) {
    this.showWalkthroughStep.next(value);
  }

  showMobileFilterButton(value) {
    this.showMobileFilterButtonValue.next(value);
  }

  setIsInScixValue(value: any) {
    this.isInScix.next(value);
  }

  setDropdownValue(value) {
    this.isDropdownOpen.next(value);
  }

  setEJDropdownValue(value) {
    this.isEJDropdownOpen.next(value);
  }

  // for ngx joyride walkthrough for SDE ,flags checks to start walkthrough on results page else home page
  updateCurrentPage(currentPage: string) {
    this.currentPageSource.next(currentPage);
  }

  // for ngx joyride walkthrough for SDE-EJ ,flags checks to start walkthrough on results page else home page
  updateCurrentPageEj(eJCurrentPage: string) {
    this.currentPageSourceEj.next(eJCurrentPage);
  }

  // Method to trigger clearing input field action
  clearInputField() {
    this.clearInputFieldSource.next(true);
  }
  openModal(template: TemplateRef<any>, config: ModalOptions) {
    this.modalRef = this.modalService.show(template, config);
  }

  closeModal() {
    this.modalRef?.hide();
  }

  isHome(): boolean {
    if (this.router.url.startsWith("/home")) return true;
    else return false;
  }

  getDefinitionData() {
    return this.httpClient
      .get("assets/tdamm-definitions.json")
      .pipe(tap((data) => {}));
  }

  /* Copy URL to clipboard */
  copyURL(record: Record, query: Query, path = "preview") {
    const appendPath = this.router.createUrlTree([path], {
      queryParams: {
        id: record.id,
        query: this.previewService.makeQuery(query).toJsonForQueryString(),
      },
    });

    if (window.location.origin.includes("localhost")) {
      this.urlCopierService.copyText(
        window.location.origin + "#" + this.router.serializeUrl(appendPath)
      );
    } else if (!window.location.toString().split("/").includes("secret-prod")) {
      this.urlCopierService.copyText(
        window.location.origin +
          "/" +
          "app" +
          "/" +
          this.startConfig.app +
          "/#" +
          this.router.serializeUrl(appendPath)
      );
    }

    this.notificationsService.notify(
      NotificationType.Success,
      "msg#actionMenu.urlCopiedToClipboard",
      {},
      undefined,
      true
    );
  }

  // Download document with docformat = pdf
  downloadDocument(record: SdeRecord) {
    if (record && record.download_url) {
      if (record.docformat === "pdf") {
        // Method - 1 - Since it is PDF the browser will open it then the user will have to download
        window.open(record?.download_url, "_blank");
      } else {
        // Method - 2 - In case of other docformats this will download files automatically
        const link = document.createElement("a");
        document.body.appendChild(link);
        link.href = record?.download_url;
        link.target = "_blank";
        link.click();
      }
    }
  }

  makeUrl(api: string): string {
    return Utils.addUrl(appConfig.feedbackURL, api);
  }

  submitContactUsForm(body) {
    if (!body) {
      return throwError({ error: "Issue with form fields" });
    }
    return this.httpClient.post<{}>(this.makeUrl(this.contactUsURL), body);
  }

  submitContentCurationForm(body) {
    if (!body) {
      return throwError({ error: "Issue with form fields" });
    }
    return this.httpClient.post<{}>(
      this.makeUrl(this.contentCurationURL),
      body
    );
  }

  navigateToEjApplicationNewTab() {
    const baseUrl = this.location.prepareExternalUrl("");
    const url = `${baseUrl}/app/${appConfig.ejApp}/#/ej/home`;

    const newTab = document.createElement("a");
    newTab.href = url;
    newTab.target = "_blank";
    newTab.rel = "noopener"; // Prevents the new tab from having control over the originating window
    newTab.click();
  }

  navigateToTdammApplicationNewTab() {
    const baseUrl = this.location.prepareExternalUrl("");
    const url = `${baseUrl}/app/${appConfig.tdammApp}/#/tdamm/home`;

    const newTab = document.createElement("a");
    newTab.href = url;
    newTab.target = "_blank";
    newTab.rel = "noopener"; // Prevents the new tab from having control over the originating window
    newTab.click();
  }

  navigateToEjApplicationExistingTab() {
    window.location.replace(
      window.location.origin + "/app/" + appConfig.ejApp + "/#/ej/home"
    );
  }
  // For Google Analytics document accessing
  createDocumentEventDetailsObject(
    docTitle: any,
    docCollection: any,
    docTreepath: any,
    docId: any,
    downloadDocument?: any,
    copyLink?: any
  ): object {
    return {
      document_title: docTitle,
      document_collection: docCollection,
      document_treepath: docTreepath,
      doc_id: docId,
      download_documunet: downloadDocument,
      copied_link: copyLink,
    };
  }

  // For search event
  createSearchEventDetailsObject(
    searchTerm: any,
    scientificFocus: string
  ): Object {
    return {
      search_term: searchTerm,
      search_scientific_focus: scientificFocus,
    };
  }

  // For Google Analytics meta data link
  createMetaDataLinkClickedObject(
    docTitle: string,
    docCollection: any,
    docTreepath: any,
    docId: string,
    UrlContentType: string,
    dataSource: string
  ): object {
    return {
      document_title: docTitle,
      document_collection: docCollection,
      document_treepath: docTreepath,
      doc_id: docId,
      UrlContent_type: UrlContentType,
      data_source: dataSource,
    };
  }

  createFilterClickObject(
    searchTerm: any,
    scientificFocus: string,
    selectedFilters: any
  ): object {
    return {
      search_term: searchTerm,
      search_scientific_focus: scientificFocus,
      selected_filters_value: selectedFilters,
    };
  }

  createScixDocumentClickedObject(
    bibcode: string,
    abstract: string,
    author: string[],
    bibstem: string[],
    publication: string,
    pubdate: string,
    title: string[]
  ): object {
    return {
      bibcode: bibcode,
      abstract: abstract,
      author: author,
      bibstem: bibstem,
      Publication: publication,
      pubdate: pubdate,
      title: title,
    };
  }
  // common method written here which is to be called from header and search-form
  trackSearchEventForGoogleAnalytics(currentSciFocus: string) {
    let searchQueryText = this.searchService.query.text;
    const eventDetails = {
      app_name: this.getAppDetailsForGA().app_name,
      url: this.router.url,
      debug_mode: appConfig.googleAnalyticsDebugMode,
      ...this.createSearchEventDetailsObject(
        searchQueryText ? searchQueryText : "EMPTY SEARCH",
        currentSciFocus
      ),
    };
    this.$gaService.event(
      googleAnalyticsConstants.action.search,
      googleAnalyticsConstants.category.search,
      searchQueryText ? searchQueryText : "EMPTY SEARCH",
      0,
      true,
      eventDetails
    );
  }

  isInTdammApp(): boolean {
    if (this.searchService.query.name === appConfig.tdammQueryService)
      return true;
    else return false;
  }

  isTdammResults(): boolean {
    if (this.router.url.startsWith("/tdamm/search")) return true;
    else return false;
  }

  isSDEApp(): boolean {
    if (this.searchService.query.name === appConfig.sdeQueryService)
      return true;
    else return false;
  }

  isTdammHome(): boolean {
    if (this.router.url.startsWith("/tdamm/home")) return true;
    else return false;
  }

  isMobileDevice(): boolean {
    let mobileUserAgents: string[] = ["iPhone", "Android"];

    let item = mobileUserAgents.find((element) =>
      navigator.userAgent.includes(element)
    );
    if (item) {
      return true;
    }
    return false;
  }

  getAppDetailsForGA(): any {
    if (this.isInTdammApp()) {
      return {
        app_name: "TDAMM",
        resultsPage: googleAnalyticsConstants.currentPage.tdammResultsPage,
        homePage: googleAnalyticsConstants.currentPage.tdammHomepPage,
        previewPage: googleAnalyticsConstants.currentPage.tdammPreviewPage,
      };
    } else if (this.isSDEApp()) {
      return {
        app_name: "SDE",
        resultsPage: googleAnalyticsConstants.currentPage.sdeResultsPage,
        homePage: googleAnalyticsConstants.currentPage.sdeHomePage,
        previewPage: googleAnalyticsConstants.currentPage.previewPage,
      };
    } else {
      return {
        app_name: "SDE",
        resultsPage: googleAnalyticsConstants.currentPage.sdeResultsPage,
        homePage: googleAnalyticsConstants.currentPage.sdeHomePage,
        previewPage: googleAnalyticsConstants.currentPage.previewPage,
      };
    }
  }
}

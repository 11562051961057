<!-- DID YOU MEAN -->
<ng-container *ngIf="isDidYouMean">
  <app-sde-did-you-mean
    [spellingCorrectionMode]="'DYMOnly'"
    [results]="didYouMeanObj"
  ></app-sde-did-you-mean>
</ng-container>
<div class="d-flex flex-row">
  <div
    class="d-flex flex-column"
    [ngClass]="{
      'width-100-p': !openedDoc || !isShortPreviewOpen
    }"
  >
    <!-- Neural Search Passages -->
    <ng-container *ngIf="commonService.isSDEApp() && enableAIAssistedSearch">
      <div
        *ngIf="
          neuralData &&
          !commonService.resultsCardLoader &&
          searchService.rowCount > 0 &&
          results.cursorRowCount > 0
        "
        class="neural-passages mt-2 mb-4"
        [ngClass]="{
          'width-35': openedDoc && isShortPreviewOpen
        }"
      >
        <app-sde-facet-card
          [type]="'top-passages'"
          class="my-3 w-100 mb-2"
          [title]="'msg#machine-learning.passages'"
        >
          <sde-top-passages
            #facet
            [results]="neuralData"
            [selectedDoc]="openedDoc"
            (passageClicked)="onDocumentClickedTopPassages($event)"
          >
          </sde-top-passages>
        </app-sde-facet-card>
      </div>
    </ng-container>

    <!-- SPONSORED LINKS -->
    <div *ngIf="searchService.rowCount > 0">
      <app-sde-sponsored-links
        [query]="searchService.query"
      ></app-sde-sponsored-links>
    </div>

    <!-- RESULT CARDS SHIMMER EFFECT -->
    <ng-container *ngIf="commonService.resultsCardLoader">
      <div
        *ngFor="
          let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
        "
        class="shimmer-box-cards shimmer-effect"
      ></div>
      <br />
    </ng-container>

    <!-- RESULT CARDS CONTAINER -->
    <ng-container *ngIf="results">
      <div class="d-flex">
        <div
          id="results-page"
          #resultCardsContainer
          class="result-cards-container"
          [ngClass]="{
            'width-35': openedDoc && isShortPreviewOpen,
            'width-100': !openedDoc && !isShortPreviewOpen
          }"
        >
          <!-- RESULT CARDS  -->
          <div
            *ngFor="let record of results.records; let index = index"
            (click)="onDocumentClicked(record, $event)"
            class="record d-flex"
            [joyrideStep]="index == 0 ? 'thirteenStep' : 'null'"
            [stepContent]="customContentThirteen"
            [ngClass]="{
              selected: record.$selected || record === openedDoc,
              'not-selected': openedDoc && record !== openedDoc
            }"
            [hoverHelpEnabled]="hoverHelpEnabled"
            [appTooltip]="
              index === 0
                ? 'Search results are displayed as cards with basic metadata. Click on a card title to open a search result in preview mode and view more information.'
                : ''
            "
          >
            <!-- EXPAND RECORD ACTION -->
            <button
              *ngIf="record !== openedDoc"
              id="openOriginalDocument"
              (click)="expandDocumentPreview(record, $event)"
              class="position-for-full-page-icon"
              title="{{ 'msg#general.openInDocumentNavigator' | sqMessage }}"
            >
              <img
                src="assets/img/expand.svg"
                class="preview-icon"
                alt="expand_doc"
              />
            </button>

            <!-- COPY LINK ACTION -->
            <button
              *ngIf="record !== openedDoc"
              id="openOriginalDocument"
              class="position-for-send-icon original-doc-btn"
              title="{{ 'msg#general.copyLinkToDocument' | sqMessage }}"
              (click)="copyURLDocumentPreview(record, $event)"
            >
              <img
                src="assets/img/copy.svg"
                class="preview-icon"
                alt="send_doc"
              />
            </button>

            <!-- SINGLE RESULT CARD -->
            <div
              class="flex-grow-1 w-75 ml-0"
              tabindex="0"
              role="region"
              aria-label="click the card title to open a search results in preview mode and view more information."
            >
              <div
                class="d-flex result-title-main-div"
                [ngClass]="{
                  'result-title-astro align-items-start':
                    record?.sourcetree1 ||
                    record?.sourcetree2 ||
                    record?.sourcetree3
                }"
              >
                <!-- RESULT CARD TITLE -->
                <app-sde-result-title
                  [appName]="currentAppLabel"
                  [record]="record"
                  class="flex-grow-1 mw-75 result-card-title me-4"
                >
                </app-sde-result-title>
              </div>

              <!-- TDAMM TAGS -->
              <ng-container *ngIf="commonService.isInTdammApp()">
                <ng-container
                  *ngIf="
                    record?.sourcetree1 ||
                    record?.sourcetree2 ||
                    record?.sourcetree3
                  "
                >
                  <div
                    class="astro-chips-container"
                    [ngClass]="{ 'astro-preview-open': openedDoc }"
                    [joyrideStep]="index == 0 ? 'tdammTagStep' : 'null'"
                    [stepContent]="tdammtagStepContent"
                  >
                    <!-- MESSENGER TAGS -->
                    <ng-container
                      *ngFor="let chip of record?.sourcetree1; let i = index"
                    >
                      <div
                        *ngIf="i < 1"
                        class="astro-chips font-family-rajdhani-medium"
                        [ngClass]="{
                          'chips-bg-messenger':
                            record.sourcetree1?.includes(chip)
                        }"
                        (click)="
                          openAstroTagsModal(astroTags);
                          $event.stopPropagation()
                        "
                      >
                        <span class="font-family-inter-extrabold"
                          >MESSENGERS</span
                        >
                        <span
                          class="messenger-chips-border"
                          *ngIf="record.sourcetree1?.includes(chip)"
                          [innerHTML]="getAstroTitleTags(chip)"
                        ></span>
                        <span
                          class="messenger-chips-border"
                          *ngIf="record.tdammMessenger?.length > 1"
                          >+{{ record.sourcetree1?.length - 1 }}</span
                        >
                      </div>
                    </ng-container>

                    <!-- OBJECT TAGS -->
                    <ng-container
                      *ngFor="let chip of record?.sourcetree2; let i = index"
                    >
                      <div
                        *ngIf="i < 1"
                        class="astro-chips font-family-rajdhani-medium"
                        [ngClass]="{
                          'chips-bg-object': record.sourcetree2?.includes(chip)
                        }"
                        (click)="
                          openAstroTagsModal(astroTags);
                          $event.stopPropagation()
                        "
                      >
                        <span class="font-family-inter-extrabold">OBJECT</span>
                        <span
                          class="object-chips-border"
                          *ngIf="record.sourcetree2?.includes(chip)"
                          [innerHTML]="getAstroTitleTags(chip)"
                        ></span>
                        <span
                          class="object-chips-border"
                          *ngIf="record.sourcetree2?.length > 1"
                          >+{{ record.sourcetree2?.length - 1 }}</span
                        >
                      </div>
                    </ng-container>

                    <!-- SIGNALS TAGS -->
                    <ng-container
                      *ngFor="let chip of record?.sourcetree3; let i = index"
                    >
                      <div
                        *ngIf="i < 1"
                        class="astro-chips font-family-rajdhani-medium"
                        [ngClass]="{
                          'chips-bg-signals': record.sourcetree3?.includes(chip)
                        }"
                        (click)="
                          openAstroTagsModal(astroTags);
                          $event.stopPropagation()
                        "
                      >
                        <span class="font-family-inter-extrabold">SIGNALS</span>
                        <span
                          class="signal-chips-border"
                          *ngIf="record.sourcetree3?.includes(chip)"
                          [innerHTML]="getAstroTitleTags(chip)"
                        ></span>
                        <span
                          class="signal-chips-border"
                          *ngIf="record.sourcetree3?.length > 1"
                          >+{{ record.sourcetree3?.length - 1 }}</span
                        >
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-template #astroTags>
                  <div
                    [ngClass]="{
                      'custom-modal': ui.screenSizeIsGreater('sm'),
                      'custom-modal-mobile': ui.screenSizeIsLessOrEqual('xs')
                    }"
                  >
                    <div class="modal-header">
                      <button
                        *ngIf="ui.screenSizeIsGreater('sm')"
                        type="button"
                        class="btn-close close pull-right"
                        aria-label="Close"
                        (click)="modalRef?.hide()"
                      >
                        <span aria-hidden="true" class="visually-hidden"
                          >&times;</span
                        >
                      </button>
                      <img
                        *ngIf="ui.screenSizeIsLessOrEqual('xs')"
                        (click)="modalRef?.hide()"
                        src="assets/img/astro-tag-close.svg"
                        alt="close-icon"
                      />
                    </div>

                    <div class="modal-body">
                      <ng-container *ngIf="record?.sourcetree1">
                        <div
                          class="tdamm-dialog-container chips-bg-messenger font-family-rajdhani-semibold"
                        >
                          <h1 class="horizontal-line">MESSENGER</h1>
                          <div class="astro-tags-dialog-section">
                            <ng-container
                              *ngFor="
                                let parent of getObjectKeys(
                                  groupItems(record?.sourcetree1)
                                );
                                let isLastParent = last
                              "
                            >
                              <ng-container
                                *ngIf="
                                  groupItems(record.sourcetree1)[parent]
                                    .length === 0
                                "
                              >
                                <div
                                  class="font-family-inter-regular chips-bg-messenger astro-tags-dialog"
                                >
                                  <div
                                    [innerHTML]="getAstroTags(parent, null)"
                                  ></div>
                                </div>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let child of groupItems(record?.sourcetree1)[
                                    parent
                                  ];
                                  let isLastChild = last
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    groupItems(record.sourcetree1)[parent]
                                      .length > 0
                                  "
                                >
                                  <div
                                    class="font-family-inter-regular chips-bg-messenger astro-tags-dialog"
                                  >
                                    <div
                                      [innerHTML]="getAstroTags(parent, child)"
                                    ></div>
                                  </div>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="!isLastParent">
                                <img
                                  class="astro-dialog-vector"
                                  src="assets/img/astro-tag-pipe.svg"
                                  alt=""
                                />
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="record?.sourcetree2">
                        <div
                          class="tdamm-dialog-container chips-bg-object font-family-rajdhani-semibold"
                        >
                          <h1 class="horizontal-line">OBJECTS</h1>
                          <div class="astro-tags-dialog-section">
                            <ng-container
                              *ngFor="
                                let parent of getObjectKeys(
                                  groupItems(record?.sourcetree2)
                                );
                                let isLastParent = last
                              "
                            >
                              <ng-container
                                *ngIf="
                                  groupItems(record.sourcetree2)[parent]
                                    .length === 0
                                "
                              >
                                <div
                                  class="font-family-inter-regular chips-bg-object astro-tags-dialog"
                                >
                                  <div
                                    [innerHTML]="getAstroTags(parent, null)"
                                  ></div>
                                </div>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let child of groupItems(record?.sourcetree2)[
                                    parent
                                  ];
                                  let isLastChild = last
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    groupItems(record.sourcetree2)[parent]
                                      .length > 0
                                  "
                                >
                                  <div
                                    class="font-family-inter-regular chips-bg-object astro-tags-dialog"
                                  >
                                    <div
                                      [innerHTML]="getAstroTags(parent, child)"
                                    ></div>
                                  </div>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="!isLastParent">
                                <img
                                  class="astro-dialog-vector"
                                  src="assets/img/astro-tag-pipe.svg"
                                  alt=""
                                />
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="record?.sourcetree3">
                        <div
                          class="tdamm-dialog-container chips-bg-signals font-family-rajdhani-semibold"
                        >
                          <h1 class="horizontal-line">SIGNALS</h1>
                          <div class="astro-tags-dialog-section">
                            <ng-container
                              *ngFor="
                                let parent of getObjectKeys(
                                  groupItems(record?.sourcetree3)
                                );
                                let isLastParent = last
                              "
                            >
                              <ng-container
                                *ngIf="
                                  groupItems(record.sourcetree3)[parent]
                                    .length === 0
                                "
                              >
                                <div
                                  class="font-family-inter-regular chips-bg-signals astro-tags-dialog"
                                >
                                  <div
                                    [innerHTML]="getAstroTags(parent, null)"
                                  ></div>
                                </div>
                              </ng-container>
                              <ng-container
                                *ngFor="
                                  let child of groupItems(record?.sourcetree3)[
                                    parent
                                  ];
                                  let isLastChild = last
                                "
                              >
                                <ng-container
                                  *ngIf="
                                    groupItems(record.sourcetree3)[parent]
                                      .length > 0
                                  "
                                >
                                  <div
                                    class="font-family-inter-regular chips-bg-signals astro-tags-dialog"
                                  >
                                    <div
                                      [innerHTML]="getAstroTags(parent, child)"
                                    ></div>
                                  </div>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="!isLastParent">
                                <img
                                  class="astro-dialog-vector"
                                  src="assets/img/astro-tag-pipe.svg"
                                  alt=""
                                />
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <!-- RESULT SOURCE TREE  -->
              <div>
                <table>
                  <tr>
                    <td>
                      <div class="d-flex" aria-hidden="true">
                        <app-sde-result-source
                          class="flex-grow-1 w-75 sq-result-source"
                          [record]="record"
                          [displayTreepath]="true"
                          title="{{ record.sourcestr56 }}"
                        >
                        </app-sde-result-source>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <!-- RESULT EXTRACTS  -->
              <sq-result-extracts
                *ngIf="record.extracts"
                [record]="record"
              ></sq-result-extracts>
              <sq-result-missing-terms
                [record]="record"
              ></sq-result-missing-terms>

              <div class="d-inline">
                <sq-labels
                  [record]="record"
                  [public]="true"
                  class="d-inline"
                ></sq-labels>
                <sq-labels
                  [record]="record"
                  [public]="false"
                  class="d-inline"
                ></sq-labels>
              </div>
            </div>
          </div>

          <!-- PAGINATION BAR (SBA)-->
          <app-sde-pager
            [results]="results"
            class="d-flex flex-column align-items-center mt-3"
            (click)="onPaginate()"
          >
          </app-sde-pager>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="short-preview width-65" *ngIf="openedDoc && isShortPreviewOpen">
    <app-sde-common-short-preview
      class="width-65"
      [openedDoc]="openedDoc"
      (closeDocumentEvent)="closeDocument()"
      (expandDocumentEvent)="expandDocumentPreview($event.document)"
      (copyUrlEvent)="copyURLDocumentPreview($event.document, undefined, true)"
      (downloadDocEvent)="downloadDocument($event.document)"
    ></app-sde-common-short-preview>
  </div>
</div>

<!-- No data found - SDE -->
<ng-container
  *ngIf="
    commonService.isSDEApp() &&
    searchService.rowCount == 0 &&
    results.cursorRowCount == 0 &&
    !didYouMeanObj &&
    !commonService.resultsCardLoader &&
    !hideNoDataTemplate
  "
>
  <no-data-found></no-data-found>
</ng-container>
<!-- No data found - TDAMM -->
<ng-container
  *ngIf="
    commonService.isInTdammApp() &&
    searchService.rowCount == 0 &&
    results.cursorRowCount == 0 &&
    !didYouMeanObj &&
    !commonService._loadingFlag &&
    !hideNoDataTemplate
  "
>
  <no-data-found></no-data-found>
</ng-container>

<ng-template #customContentThirteen>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title"
      style="color: rgb(2, 61, 139)"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      {{ getWalkthroughLabelForResultCard() }}
    </div>
  </div>

  <p class="joyride-content">
    Search results are displayed as cards with basic metadata. Click on a card
    title to open a search result in preview mode.
  </p>
</ng-template>

<ng-template #tdammtagStepContent>
  <div class="joyride-step-header">
    <div
      class="joyride-step-title"
      [ngClass]="{
        'w-75': ui.screenSizeIsLessOrEqual('sm'),
        'w-100': ui.screenSizeIsGreater('sm')
      }"
    >
      {{
        ui.screenSizeIsLessOrEqual("xs") ? "6 - TDAMM TAGS" : "7 - TDAMM TAGS"
      }}
    </div>
  </div>

  <p class="joyride-content">
    Tags above each search result identify messenger, object and signal types
    and subtypes that correspond to the result.
  </p>
  <p class="joyride-content">
    Clicking on any TDAMM tag opens a window with more detailed information
    about the tag categories and subcategories.
  </p>
</ng-template>

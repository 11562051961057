import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { JoyrideService } from "ngx-joyride";
import { googleAnalyticsConstants } from "../analytics/google-analytics.constant";
import { appConfig } from "../app-config/app-config";
import { CommonService } from "../services/common.service";
import { HoverHelpSharedService } from "../services/hover-help-shared.service";

@Component({
  selector: "sde-common-help-support-modal",
  templateUrl: "./sde-common-help-support-modal.component.html",
  styleUrls: ["./sde-common-help-support-modal.component.scss"],
})
export class SdeCommonHelpSupportModalComponent implements OnInit {
  source: string;
  isInSciX: boolean = false;
  activeImage: string = "";

  constructor(
    public modalRef: BsModalRef,
    private readonly joyrideService: JoyrideService,
    public commonService: CommonService,
    public hoverHelpSharedService: HoverHelpSharedService,
    protected ui: UIService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private searchService: SearchService,
    private $gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.checkRouteAndReturnCurrentPageForGA();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  clickFeedback(newImage: string) {
    switch (true) {
      // NB: Souce value hardcoded in the case since we have to keep SDE for TDAMM as well
      case this.commonService.getAppDetailsForGA().app_name == "TDAMM":
        this.router.navigate(["tdamm/contact-us"], {
          queryParams: {
            source: "SDE",
          },
        });
        break;
      // this.commonService.getAppDetailsForGA().app_name,

      default:
        this.router.navigate(["/contact-us"], {
          queryParams: {
            source: this.commonService.getAppDetailsForGA().app_name,
          },
        });
        break;
    }
    this.modalRef.hide();
    this.activeImage = newImage;
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.feedback,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.checkRouteAndReturnCurrentPageForGA(),
        url: this.router.url,
        debug_mode: "true",
      }
    );
  }

  hoverFeedback(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverFeedback(newImage: string) {
    this.activeImage = newImage;
  }

  clickFaq(newImage: string) {
    this.router.navigate(["/HelpPage"]);
    this.activeImage = newImage;
    this.modalRef.hide();
    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.faqPage,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.checkRouteAndReturnCurrentPageForGA(),
        url: this.router.url,
        debug_mode: "true",
      }
    );
  }

  hoverFaq(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverFaq(originalImage: string) {
    this.activeImage = originalImage;
  }

  hoverHoverHelp(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverHoverHelp(originalImage: string) {
    this.activeImage = originalImage;
  }

  // Walkthrough check updated to use common checks rather than values passed
  clickWalkthrough(newImage: string) {
    if (
      this.checkRouteAndReturnCurrentPageForGA().includes("Home") &&
      !this.commonService.isInTdammApp()
    ) {
      this.activeImage = newImage;
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: [
            "firstStep",
            "secondStep@home",
            "thirdStep@home",
            "fourthStep@home",
            "fifthStep@home",
            "sixthStep@home",
            "seventhStep@home",
          ],
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: this.commonService.getAppDetailsForGA().app_name,
          page: this.checkRouteAndReturnCurrentPageForGA(),
          url: this.router.url,
          debug_mode: appConfig.googleAnalyticsDebugMode,
        }
      );
    } else if (this.checkRouteAndReturnCurrentPageForGA().includes("Results")) {
      let filterStep;
      let stepsForWalkthrough;
      if (this.isMobile) {
        filterStep = "mobileFilterStep";
      } else {
        filterStep = "eleventhStep";
      }
      if (
        this.checkRouteAndReturnCurrentPageForGA().includes("Results") &&
        this.isInSciX
      ) {
        stepsForWalkthrough = [
          "seventhStep",
          "eightStep",
          "ninethStep",
          "tenthStep",
          "twelveStep",
          "thirteenStep",
        ];
      } else if (this.searchService.rowCount == 0) {
        if (this.commonService.getAppDetailsForGA().app_name == "TDAMM") {
          stepsForWalkthrough = [
            "seventhStep",
            "eightStep",
            "tenthStep",
            filterStep,
            "twelveStep",
          ];
        } else {
          stepsForWalkthrough = [
            "seventhStep",
            "eightStep",
            "step2",
            "ninethStep",
            "tenthStep",
            filterStep,
            "twelveStep",
          ];
        }
      } else if (
        this.checkRouteAndReturnCurrentPageForGA().includes("Results") &&
        this.commonService.getAppDetailsForGA().app_name == "TDAMM"
      ) {
        stepsForWalkthrough = [
          "firstStepForTdammResults",
          "seventhStep",
          "eightStep",
          "tenthStep",
          filterStep,
          "twelveStep",
          "thirteenStep",
          "tdammTagStep",
        ];
      } else {
        stepsForWalkthrough = [
          "seventhStep",
          "eightStep",
          "step2",
          "ninethStep",
          "tenthStep",
          filterStep,
          "twelveStep",
          "thirteenStep",
        ];
      }

      this.activeImage = newImage;
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: stepsForWalkthrough,
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: this.commonService.getAppDetailsForGA().app_name,
          page: this.checkRouteAndReturnCurrentPageForGA(),
          url: this.router.url,
          debug_mode: appConfig.googleAnalyticsDebugMode,
        }
      );
    } else if (this.commonService.getAppDetailsForGA().app_name == "TDAMM") {
      let stepsForTdamm;
      if (this.ui.screenSizeIsLessOrEqual("xs")) {
        stepsForTdamm = [
          "firstStepForTdamm",
          "secondStepForTdammMobile",
          "thirdStepForTdammMobile",
          "fourthStepForTdammMobile",
          "fifthStepForTdammMobile",
        ];
      } else {
        stepsForTdamm = [
          "firstStepForTdamm",
          "secondStepForTdamm",
          "thirdStepForTdamm",
          "fourthStepForTdamm",
          "fifthStepForTdamm",
        ];
      }
      this.joyrideService.startTour(
        {
          logsEnabled: false,
          steps: stepsForTdamm,
          themeColor: "#023D8B",
          showCounter: false,
        } // Your steps order
      );
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.walkthrough,
        0,
        true,
        {
          app_name: this.commonService.getAppDetailsForGA().app_name,
          page: this.checkRouteAndReturnCurrentPageForGA(),
          url: this.router.url,
          debug_mode: appConfig.googleAnalyticsDebugMode,
        }
      );
    }
    this.modalRef.hide();
  }

  hoverWalkthrough(newImage: string) {
    this.activeImage = newImage;
  }

  unhoverWalkthrough(originalImage: string) {
    this.activeImage = originalImage;
  }

  toggleHoverHelp(newImage: string): void {
    this.hoverHelpSharedService.toggleHoverHelp(true);
    this.activeImage = newImage;
    this.modalRef.hide();

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.hoverHelp,
      0,
      true,
      {
        app_name: this.commonService.getAppDetailsForGA().app_name,
        page: this.checkRouteAndReturnCurrentPageForGA(),
        url: this.router.url,
        debug_mode: "true",
      }
    );
  }

  // Since this is a common component between SDE,TDAMM and across home & results page
  // This method will check the route and give the page name
  checkRouteAndReturnCurrentPageForGA() {
    switch (true) {
      case this.router.url.includes("/home") ||
        this.router.url.includes("/tdamm/home"):
        return this.commonService.getAppDetailsForGA().homePage;

      case this.router.url.includes("/search") ||
        this.router.url.includes("/tdamm/search"):
        // This if condition is specifically added for publications tab
        if (
          decodeURIComponent(this.router.url).includes(`"tab":"Publications"`)
        ) {
          this.isInSciX = true;
        }
        return this.commonService.getAppDetailsForGA().resultsPage;

      default:
        break;
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sde-common-nasa-loader',
  templateUrl: './sde-common-nasa-loader.component.html',
  styleUrls: ['./sde-common-nasa-loader.component.scss']
})
export class SdeCommonNasaLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

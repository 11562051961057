import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BsNotificationModule } from "@sinequa/components/notification";
import { UtilsModule } from "@sinequa/components/utils";
import { IntlModule } from "@sinequa/core/intl/intl.module";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule, TabsetConfig } from "ngx-bootstrap/tabs";
import { EllipsisModule } from "ngx-ellipsis";
import { NgxMasonryModule } from "ngx-masonry";
import { NestedEllipsisModule } from "ngx-nested-ellipsis";
import { CommonService } from "../services/common.service";
import { HighlightPipe } from "./../../pipes/highlight-pipe";
import { LinkDetectorPipe } from "./../../pipes/link-detector.pipe";
import { SearchPipe } from "./../../pipes/search-pipe";

import { SdeCommonMetadataViewerComponent } from "./sde-common-metadata-viewer.component";
@NgModule({
  exports: [
    CommonModule,
    PopoverModule,
    TabsModule,
    BsDropdownModule,
    FontAwesomeModule,
    SdeCommonMetadataViewerComponent,
  ],
  declarations: [
    SdeCommonMetadataViewerComponent,
    SearchPipe,
    HighlightPipe,
    LinkDetectorPipe,
  ],
  providers: [TabsetConfig, BsDropdownConfig, CommonService, DatePipe],
  imports: [
    CommonModule,
    PopoverModule,
    TabsModule,
    BsDropdownModule,
    FontAwesomeModule,
    FormsModule,
    NgxMasonryModule,
    UtilsModule,
    EllipsisModule,
    NestedEllipsisModule,
    ModalModule.forRoot(),
    IntlModule,
    BsNotificationModule,
  ],
})
export class SdeCommonMetadataViewerModule {}

export const googleAnalyticsConstants = {
  category: {
    button: "button",
    search: "search",
    link: "link",
    card: "card",
    tab: "tab",
    dropdown: "dropdown",
    toggle: "toggle",

    // Add more categories as needed
  },
  action: {
    click: "click",
    search: "search",
    tabChange: "change",
    select: "select",
    toggleOn: "toggle on",
    toggleOff: "toggle off",
    // Add more actions as needed
  },
  label: {
    environmentalJustice: "Environmental Justice Data Search Interface",
    sde: "Science Discovery Engine",
    tdamm: "Time Domain And Multi-Messenger Astronomy Search Interface",
    sdeHelpAndSupport: "SDE - Help & Support",
    faqPage: "SDE - FAQs",
    walkthrough: "SDE - Walkthrough",
    feedback: "SDE - Contact Us",
    hoverHelp: "SDE - Hover Help",
    apiDocumentation: "API documentation",
    acronymSearch: "SDE - Acronym search ",
    mySearchStory: "My Search Story",
    sciX: "Explore SciX",
    toggleScientificFocusDropdown: "Toggle Scientific Focus Dropdown",
    clickResultsDocument: "Document",
    citedDocument: "Cited Document",
    openDocumentNavigator: "Open Document in Navigator",
    copyDocumentLink: "Copy Link to Document",
    metaDataLink: "Meta-Data Link ",
    toggleEntities: "Toggle Entities",
    toggleRelevantExtracts: "Toggle Relevant Extracts",
    openOrigDocumentFromSource: "Open Original Document from Source",
    downloadDocument: "Download the  Document",
    aiAssistedSearch: "AI Assisted Search",
    // Add more labels as needed
  },
  currentPage: {
    sdeHomePage: "Science Discovery Engine - Home",
    sdeResultsPage: "Science Discovery Engine - Results",
    contactUsPage: "Science Discovery Engine - Contact Us",
    contentCurationRequest:
      "Science Discovery Engine - Content Curation Request",
    previewPage: "Science Discovery Engine - Document Navigator",
    helpPage: "Science Discovery Engine - FAQs",
    mySearchStory: "Science Discovery Engine - My Search Story",
    tdammHomepPage:
      "Time Domain And Multi-Messenger Astronomy Search Interface - Home",
    tdammResultsPage:
      "Time Domain And Multi-Messenger Astronomy Search Interface - Results",
    tdammPreviewPage:
      "Time Domain And Multi-Messenger Astronomy Search Interface - Document Navigator",
    // Add more pages as needed
  },
  // You can add more constants like page tabs, query parameters, etc.
};

export default googleAnalyticsConstants;

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { UIService } from "@sinequa/components/utils";
import { LoginService } from "@sinequa/core/login";
import { throwError } from "rxjs";
import { CommonService } from "../services/common.service";
import googleAnalyticsConstants from "./../analytics/google-analytics.constant";

@Component({
  selector: "sde-common-contact-us-page",
  templateUrl: "./sde-common-contact-us-page.component.html",
  styleUrls: ["./sde-common-contact-us-page.component.scss"],
})
export class SdeCommonContactUsPageComponent implements OnInit {
  contactUsForm: FormGroup;
  contactUsFormSubmitted: boolean = false;
  contactUsContainer: any;
  userFullName: string | undefined = "";
  notifications: [];
  source: string = "SDE";

  constructor(
    protected commonService: CommonService,
    private titleService: Title,
    public loginService: LoginService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    public ui: UIService
  ) {
    this.route.queryParamMap.subscribe((p: any) => {
      if (p?.params?.source) this.source = p.params.source;
    });
    // this.ui.screenSizeIsLessOrEqual;
  }

  ngOnInit(): void {
    this.titleService.setTitle(
      googleAnalyticsConstants.currentPage.contactUsPage
    );

    this.contactUsForm = new FormGroup({
      firstName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]*$"),
        this.htmlValidator(),
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.email,
        this.htmlValidator(),
      ]),
      subject: new FormControl("", [
        Validators.required,
        Validators.maxLength(100),
        this.htmlValidator(),
      ]),
      comments: new FormControl("", [
        Validators.required,
        Validators.maxLength(500),
        this.htmlValidator(),
      ]),
    });
  }
  get f() {
    return this.contactUsForm.controls;
  }

  goBack() {
    this._location.back();
  }

  goBackToEjHome() {
    this.router.navigate(["ej/home"]);
  }

  goBackToSDEHome() {
    this.router.navigate(["/home"]);
  }

  // Custom HTML validator function to show an error in case HTML is entered in any of the fielfs
  htmlValidator() {
    return (control: FormControl): ValidationErrors | null => {
      const htmlTagPattern = /<\/?[a-z][\s\S]*>/i; // Regex to detect HTML tags
      const isHtml = htmlTagPattern.test(control.value);
      return isHtml ? { htmlError: "HTML content is not allowed" } : null;
    };
  }

  // Added to avoid XSS script attacks. Scripts will be converted to plain text and then submitted.
  escapeHtml(input: string): string {
    const div = document.createElement("div");
    div.innerText = input;
    return div.innerHTML;
  }

  onSubmitContactUsForm() {
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched();
      this.contactUsFormSubmitted = false;
    } else {
      let request = {
        name: this.escapeHtml(this.contactUsForm.value.firstName),
        email: this.escapeHtml(this.contactUsForm.value.email),
        subject: this.escapeHtml(this.contactUsForm.value.subject),
        comments: this.escapeHtml(this.contactUsForm.value.comments),
        source: this.source,
      };

      this.commonService.submitContactUsForm(request).subscribe({
        next: (response) => {
          this.contactUsFormSubmitted = true;
        },
        error: (error) => {
          throwError(() => new Error("Please try again later"));
        },
      });
    }
  }
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import {
  NotificationType,
  NotificationsService,
} from "@sinequa/core/notification";
import { Record } from "@sinequa/core/web-services";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { SdePreviewData } from "../../model/extended.interface";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { CommonService } from "../services/common.service";

@Component({
  selector: "app-sde-event-based-metadata-viewer",
  templateUrl: "./sde-event-based-metadata-viewer.component.html",
  styleUrls: ["./sde-event-based-metadata-viewer.component.scss"],
})
export class SdeEventBasedMetadataViewerComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private previewService: PreviewService,
    private searchService: SearchService,
    private $gaService: GoogleAnalyticsService,
    private router: Router,
    public ui: UIService,
    private deviceService: DeviceDetectorService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private notificationsService: NotificationsService
  ) {}

  @Input() previewData: SdePreviewData;
  recordData: any;

  coordinatesLocationArray: string[] = [];
  /**
   * This output property is used to send an event to inform other components that data is loaded in metadata viewer.
   */
  @Output() dataLoadedEvent = new EventEmitter();
  /**
   * This input property is used to determine the type of viewer document/metadata.
   */
  @Input() previewType: string = "default";
  /**
   * This property is used to determine whether metadata description box is expanded/collapsed.
   */
  expandedText: boolean;
  @ViewChild("metadataContent") metadataContent: ElementRef;

  activeSection: string = "data";

  // for publication date
  dateFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  sectionElements!: QueryList<ElementRef>;
  observer!: IntersectionObserver;
  @ViewChild("leftSection") leftSection: ElementRef;
  @ViewChild("rightSection") rightSection: ElementRef;
  @ViewChild("middleSection") middleSection: ElementRef;

  /**
   * This property holds metadata viewer action icons, tooltip text and functionality.
   */
  metadataViewerOptions: any = [
    {
      tooltip: "msg#general.openInPreview",
      function: () => {
        // this.searchService.loadingBetweenComponents(false);
        this.previewService.openRoute(
          this.previewData.record as Record,
          this.searchService.query
        );

        this.$gaService.event(
          googleAnalyticsConstants.action.click,
          googleAnalyticsConstants.category.button,
          googleAnalyticsConstants.label.openDocumentNavigator,
          0,
          true,
          {
            app_name: this.commonService.getAppDetailsForGA().app_name,
            page: this.commonService.getAppDetailsForGA().previewPage,
            url: this.router.url,
            debug_mode: "true",

            ...this.commonService.createDocumentEventDetailsObject(
              this.previewData.record.title,
              this.previewData.record.collection,
              this.previewData.record.treepath,
              this.previewData.record.id
            ),
          }
        );
      },
      imageSrc: "assets/img/expand.svg",
      hide: this.ui.screenSizeIsLessOrEqual("sm"),
      alt: "Open document in full screen mode in document navigator",
    },
    {
      tooltip: "msg#general.copyLinkToDocument",
      function: () => {
        this.copyURLDocumentPreview(this.previewData.record);
        this.$gaService.event(
          googleAnalyticsConstants.action.click,
          googleAnalyticsConstants.category.button,
          googleAnalyticsConstants.label.copyDocumentLink,
          0,
          true,
          {
            app_name: this.commonService.getAppDetailsForGA().app_name,
            page: this.commonService.getAppDetailsForGA().resultsPage,
            url: this.router.url,
            debug_mode: "true",

            ...this.commonService.createDocumentEventDetailsObject(
              this.previewData.record.title,
              this.previewData.record.collection,
              this.previewData.record.treepath,
              this.previewData.record.id
            ),
          }
        );
      },
      imageSrc: "assets/img/copy.svg",
      hide: false,
      alt: "Copy link to the document",
    },
    {
      tooltip: "msg#general.closeDocumentPreview",
      function: () => {
        this.commonService.closeMetadataViewer.next(true);
      },
      imageSrc: "assets/img/close.svg",
      hide: false,
      alt: "Close preview mode",
    },
  ];

  preprocessedContent: any;

  ngOnInit(): void {
    // this.highlightContent(this.activeSection);

    if (this.previewData) {
      this.dataLoadedEvent.emit(true);
    }
    this.recordData = this.previewData.record;

    this.preprocessedContent = this.getProcessedContent(
      this.recordData?.data_product_desc
    );

    this.coordinatesLocationArray = this.generateCoordinatesLocationString(
      this.recordData
    );
  }

  getProcessedContent(content: string | undefined): string {
    if (!content) return "";

    // Replace \n with <br>
    const withLineBreaks = content.replace(/\n/g, "<br>");

    // Convert URLs to anchor tags
    const urlRegex = /(\bhttps?:\/\/[^\s<>]+(?<![.,!?))]))/g; // Matches URLs starting with http:// or https://
    const withLinks = withLineBreaks.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return withLinks;
  }

  ngAfterViewInit(): void {
    this.setMetadataContentStyle();
    const options = {
      root: document.querySelector("#metadataContent"),
      rootMargin: "0px",
      threshold: 0.05, // Trigger when 10% of the div is visible
    };

    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      options
    );

    // Observe the "Data" section
    if (this.leftSection) {
      this.observer.observe(this.leftSection.nativeElement);
    }

    // Observe the "Summary" section
    if (this.rightSection) {
      this.observer.observe(this.rightSection.nativeElement);
    }

    // Observe the "Attributes" section
    if (this.middleSection) {
      this.observer.observe(this.middleSection.nativeElement);
    }

    // Observe each section for intersection
    this.sectionElements.forEach((section: ElementRef) => {
      this.observer.observe(section.nativeElement);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["previewData"]) {
      // Detect if previewData has changed
      this.setMetadataContentStyle();
    }
  }
  ngAfterViewChecked(): void {
    this.setMetadataContentStyle();
  }

  handleIntersection(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const id = entry.target.id; // Access the id directly from the target
        if (id) {
          this.activeSection = id; // Update the active section when it's visible

          if (entry.intersectionRatio == 1) {
            this.activeSection = "data";
          }
          this.cdr.detectChanges();
        }
      }
    });
  }

  triggerScroll(target: string) {
    const element = document.getElementById(target);

    if (element) {
      this.activeSection = target;
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      // Optionally highlight the content after scrolling
      setTimeout(() => {
        this.highlightContent(target);
      }, 500);
    }
  }

  highlightContent(targetContentId: string) {
    const contentElement = document.getElementById(targetContentId);

    if (contentElement) {
      this.renderer.addClass(contentElement, "highlight");
      setTimeout(() => {
        this.renderer.removeClass(contentElement, "highlight");
      }, 1000);
    }
  }

  setMetadataContentStyle() {
    this.cdr.detectChanges();
    let top = this.metadataContent?.nativeElement?.getBoundingClientRect().top;
    // Calculate the new height by subtracting the 'top' from the window's inner height
    let newHeight = window.innerHeight - top;

    // Set the new height for the metadataContent element
    this.metadataContent.nativeElement.style.height = `${newHeight}px`;
  }

  /**
   * Copy URL of Metadata Preview from small preview on results page
   */
  copyURLDocumentPreview(record: Record) {
    this.commonService.copyURL(record as Record, this.searchService.query);
    this.notificationsService.notify(
      NotificationType.Success,
      "msg#actionMenu.urlCopiedToClipboard",
      {},
      undefined,
      true
    );
  }

  // Method to check if the record is from HEK
  isHeliophysicsEventsKnowledgebase(): boolean {
    const treepath = this.recordData?.treepath;
    return !!(
      treepath &&
      treepath.includes("/Heliophysics/Heliophysics Events Knowledgebase/")
    );
  }

  // Method to check if the record is from GCN Circulars
  isGCNCirculars(): boolean {
    const treepath = this.recordData?.treepath;
    return !!(treepath && treepath.includes("/Astrophysics/GCN Circulars/"));
  }

  /**
   * This method is used to generate science keywords string
   * @param {any} details - holds metadata
   * @returns {any[]} - array containing scientific keywords
   */
  generateCoordinatesLocationString(details: any) {
    if (details && details.coord) {
      return [...details.coord];
    } else {
      return [];
    }
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  check($event) {}
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// children components
import { PreviewRelatedDocsPanelComponent } from "./preview-related-docs-panel/preview-related-docs-panel.component";
import { FullPagePreviewComponent } from "./sde-common-full-preview.component";

const routes: Routes = [
  { path: "preview", component: FullPagePreviewComponent },
  {
    path: "preview-related-docs-panel",
    component: PreviewRelatedDocsPanelComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)], //registered here
  exports: [RouterModule],
})
export class FullPagePreviewRoutingModule {}

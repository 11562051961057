import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BsActionModule } from "@sinequa/components/action";
import { CollapseModule } from "@sinequa/components/collapse";
import { MLModule } from "@sinequa/components/machine-learning";
import { BsSearchModule } from "@sinequa/components/search";
import { UtilsModule } from "@sinequa/components/utils";
import { IntlModule } from "@sinequa/core/intl";
import { SdeEventBasedMetadataViewerModule } from "../app/sde-event-based-metadata-viewer/sde-event-based-metadata-viewer.module";
import { SdeResultTitleComponent } from "../inherited/sde-result-title/sde-result-title.component";
import { SdeCommonMetadataViewerModule } from "./../app/sde-common-metadata-viewer/sde-common-metadata-viewer.module";
import { SdeFacetCardComponent } from "./../inherited/sde-facet-card/sde-facet-card.component";
import { SdeFacetListComponent } from "./../inherited/sde-facet-list/sde-facet-list.component";
import { SdePagerComponent } from "./../inherited/sde-pager/sde-pager.component";
import { SdePreviewEntityFacetComponent } from "./../inherited/sde-preview-entity-facet/sde-preview-entity-facet.component";
import { SdePreviewEntityPanelComponent } from "./../inherited/sde-preview-entity-panel/sde-preview-entity-panel.component";
import { SdePreviewExtractsPanelComponent } from "./../inherited/sde-preview-extracts-panel/sde-preview-extracts-panel.component";
import { SdePreviewRelatedDocsPanelComponent } from "./../inherited/sde-preview-related-docs-panel/sde-preview-related-docs-panel.component";
import { SdePreviewComponent } from "./../inherited/sde-preview/sde-preview.component";
import { SdeResultSourceComponent } from "./../inherited/sde-result-source/sde-result-source.component";
import { SdeAppliedFiltersListComponent } from "./sde-applied-filters-list/sde-applied-filters-list.component";
import { SdeAppliedFiltersComponent } from "./sde-applied-filters/sde-applied-filters.component";
import { SdeDidYouMeanComponent } from "./sde-did-you-mean/sde-did-you-mean.component";
import { SdeSponsoredLinksComponent } from "./sde-sponsored-links/sde-sponsored-links.component";
import { SdeTabsComponent } from "./sde-tabs/sde-tabs.component";
import { SdeTopPassagesComponent } from "./sde-top-passages/sde-top-passages.component";
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SdeCommonMetadataViewerModule,
    SdeEventBasedMetadataViewerModule,
    BsSearchModule,
    UtilsModule,
    IntlModule,
    CollapseModule,
    BsActionModule,
    MLModule,
  ],
  exports: [
    IntlModule,
    SdePreviewComponent,
    SdeFacetCardComponent,
    SdeFacetListComponent,
    SdePreviewEntityFacetComponent,
    SdePreviewEntityPanelComponent,
    SdePreviewExtractsPanelComponent,
    SdePreviewRelatedDocsPanelComponent,
    SdePagerComponent,
    SdeResultTitleComponent,
    SdeResultSourceComponent,
    SdeResultTitleComponent,
    SdeAppliedFiltersComponent,
    SdeSponsoredLinksComponent,
    SdeDidYouMeanComponent,
    SdeTopPassagesComponent,
    SdeTabsComponent,
  ],
  declarations: [
    SdePreviewComponent,
    SdeFacetCardComponent,
    SdeFacetListComponent,
    SdePreviewEntityFacetComponent,
    SdePreviewEntityPanelComponent,
    SdePreviewExtractsPanelComponent,
    SdePreviewRelatedDocsPanelComponent,
    SdePagerComponent,
    SdeResultTitleComponent,
    SdeResultSourceComponent,
    SdeResultTitleComponent,
    SdeAppliedFiltersComponent,
    SdeAppliedFiltersListComponent,
    SdeSponsoredLinksComponent,
    SdeDidYouMeanComponent,
    SdeTopPassagesComponent,
    SdeTabsComponent,
  ],
  providers: [],
})
export class InheritedModule {}
